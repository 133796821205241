import { Container } from "@material-ui/core";
import ItemCategoriesContent from "./components/ItemCategoriesContent";

const ItemCategories = () => {
  return (
    <Container maxWidth="md">
      <ItemCategoriesContent />
    </Container>
  );
};

export default ItemCategories;
