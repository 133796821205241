import { Box, Button, IconButton, Tooltip } from "@material-ui/core";
import {
  AddAlarm,
  AddBox,
  Assignment,
  CheckBoxOutlineBlankOutlined,
  CheckBoxOutlined,
  Edit,
} from "@material-ui/icons";
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useHeader } from "components/Layout/HeaderContext";
import PageableTable from "components/PageableTable/PageableTable";
import { STATUSES } from "config/constants";
import { RootState } from "config/store";
import supportedLocales from "config/supportedLocales";
import { format, isPast, subDays, subWeeks } from "date-fns";
import i18n from "i18n";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import { listIssues } from "shared/network/issues.api";
import { useGetSessionStorageKey } from "views/Comment/function";
import { TableState } from "views/Company/CompanyList";
import { useColoredRowStyles } from "./NewIssueOverviewList";

type Props = {
  fromIssueGenerate?: boolean;
  isSelected?: (item: any) => boolean;
  addToActiveList?: (item: any) => void;
  removeFromActiveList?: (item: any) => void;
};

const IssueList = ({
  fromIssueGenerate,
  isSelected,
  addToActiveList,
  removeFromActiveList,
}: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const colorClasses = useColoredRowStyles();

  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);

  const { setHeaderButtons } = useHeader();

  const isPartner =
    account.user.relUserCompany?.length !== 0 &&
    !account.user.isSuperAdmin &&
    !selectedRelTenant.isTenantAdmin;

  const [sortState, setSortState] = useState("");
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-issue-page-number")) ||
        JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-issue-page-size")) ||
        JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });
  const { page, pageSize } = tableState;

  const issueList = useQuery(
    ["listIssueQuery", page, pageSize, sortState, selectedRelTenant.tenant.id],
    async () => {
      const { data } = await listIssues(
        page,
        pageSize,
        selectedRelTenant.tenant.id,
        isPartner ? `company.id=${account?.user?.relUserCompany?.[0]?.company.id}` : "", ///????
        sortState,
      );
      return data;
    },
  );

  const columns: GridColDef[] = [
    {
      field: "multiSelect",
      headerName: " ",
      width: 50,
      hide: !fromIssueGenerate,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          {isSelected && isSelected(row) ? (
            <IconButton
              size="small"
              color="primary"
              onClick={() => {
                removeFromActiveList && removeFromActiveList(row);
              }}
            >
              <CheckBoxOutlined />
            </IconButton>
          ) : (
            <IconButton
              size="small"
              color="primary"
              onClick={() => {
                addToActiveList && addToActiveList(row);
              }}
            >
              <CheckBoxOutlineBlankOutlined />
            </IconButton>
          )}
        </Box>
      ),
    },
    {
      field: "id",
      headerName: t("issues.formValues.id"),
      flex: 1,
    },
    {
      field: "status",
      headerName: t("issues.formValues.status"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => t(`common:statuses.${row.status}`),
      type: "select",
    },
    {
      field: "name",
      headerName: t("issues.formValues.subject"),
      flex: 3,
    },
    {
      field: "company.name",
      headerName: t("issues.formValues.company"),
      flex: 2,
      valueGetter: ({ row }: GridValueGetterParams) => (row.company ? row.company.name : ""),
    },
    {
      field: "project.name",
      headerName: t("issues.formValues.project"),
      flex: 3,
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.project ? row.project.name : t("common:notification.NO_PROJECT"),
    },
    {
      field: "contract.name",
      headerName: t("issues.formValues.contract"),
      flex: 3,
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.contract ? row.contract.name : t("common:notification.NO_CONTRACT"),
    },
    {
      field: "startDate",
      headerName: t("issues.formValues.startDate"),
      flex: 2,
      valueGetter: ({ row }: GridValueGetterParams) =>
        format(new Date(row.startDate), "P", {
          locale: supportedLocales[i18n.language],
        }),
      type: "singleDateStart",
    },
    {
      field: "endDate",
      headerName: t("issues.formValues.endDate"),
      flex: 2,
      valueGetter: ({ row }: GridValueGetterParams) =>
        format(new Date(row.endDate), "P", {
          locale: supportedLocales[i18n.language],
        }),
      type: "singleDateEnd",
    },
    {
      field: "actions",
      headerName: " ",
      width: 140,
      sortable: false,
      hide: fromIssueGenerate,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          {hasAuthority(account.user, account.permissions, selectedRelTenant, ["ISSUE_GET"]) && (
            <Tooltip title={t("timeEntry.title").toString()}>
              <IconButton
                size="small"
                color="primary"
                component={Link}
                to={`/issues/all/time-entry-create?id=${row.id}`}
              >
                <AddAlarm />
              </IconButton>
            </Tooltip>
          )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, ["ISSUE_GET"]) && (
            <Tooltip title={t("issues.issueDetails").toString()}>
              <IconButton
                component={Link}
                to={`/issues/all/issue-details?id=${row.id}`}
                size="small"
                color="primary"
                style={{ margin: "0 8px" }}
              >
                <Assignment color="primary" />
              </IconButton>
            </Tooltip>
          )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, ["ISSUE_ADMIN"]) && (
            <Tooltip title={t("issues.modifyIssue").toString()}>
              <IconButton
                component={Link}
                to={`/issues/all/issue-modify?id=${row.id}`}
                size="small"
                color="primary"
                style={{ margin: "0 8px" }}
              >
                <Edit color="primary" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
    },
  ];

  useEffect(() => {
    !fromIssueGenerate &&
      setHeaderButtons(
        <Box display="flex" gridGap={12}>
          {hasAuthority(account.user, account.permissions, selectedRelTenant, ["ISSUE_GET"]) && (
            <Button
              variant="outlined"
              component={Link}
              to="/issues/all/time-entry-create"
              startIcon={<AddAlarm />}
            >
              {t("timeEntry.title")}
            </Button>
          )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, ["ISSUE_ADMIN"]) && (
            <Button component={Link} to="/issues/all/issue-create" startIcon={<AddBox />}>
              {t("issues.createIssue")}
            </Button>
          )}
        </Box>,
      );
    return () => {
      setHeaderButtons(null);
    };
  }, [account.user, account.permissions, selectedRelTenant]); //eslint-disable-line

  function onCellClick(params: GridCellParams) {
    if (!["actions", "multiSelect"].includes(params.field) /*params.field !== "actions"*/) {
      history.push(`/issues/all/issue-details?id=${params.row.id}`);
    }
  }

  return (
    <PageableTable
      filterOptions={[
        {
          columnName: "status",
          options: STATUSES.map(value => {
            return {
              translated: t(`common:statuses.${value}`),
              value,
            };
          }),
        },
      ]}
      filterable={[
        "id",
        "status",
        "name",
        "company.name",
        "project.name",
        "startDate",
        "endDate",
        "contract.name",
      ]} //ha a backend jó, akkor ezt ki kell majd szedni
      sortState={sortState}
      setSortState={setSortState}
      sessionStorageKey="issue"
      tableState={tableState}
      setTableState={setTableState}
      columns={columns}
      query={issueList}
      onCellClick={onCellClick}
      getRowClassName={params => {
        if (selectedRelTenant?.tenant?.isIssueColoring) {
          if (params.row?.status === "CLOSED") {
            return colorClasses.green;
          } else if (isPast(new Date(params.row?.endDate))) {
            return colorClasses.red;
          } else if (isPast(subDays(new Date(params.row?.endDate), 7))) {
            return colorClasses.orange;
          } else {
            return colorClasses.green;
          }
        } else return "";
      }}
    />
  );
};
export default IssueList;
