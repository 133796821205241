import { Box, Container, Tab, Tabs } from "@material-ui/core";
import { useHeader } from "components/Layout/HeaderContext";
import PrivateRoute from "components/PrivateRoute";
import { RootState } from "config/store";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, Redirect, Switch, useLocation } from "react-router-dom";
import { hasAnyAuthority } from "shared/authorization";
import TenantAllInvoice from "views/Admin/Tenants/TenantAllInvoice";
import CashRegisterList from "views/CashRegister/CashRegisterList";
import IncomingInvoiceList from "./IncomingInvoice/IncomingInvoiceList";
import InvoiceGenerate from "./InvoiceGenerate";
import InvoiceTransactionList from "./InvoiceTransactionList";
import InvoiceTransactionSummary from "./InvoiceTransactionSummary";

export const INVOICES_TABS = [
  `/invoices/invoices-list`,
  `/invoices/incoming-list`,
  `/invoices/cash-register`,
  `/invoices/transactions`,
  `/invoices/transaction-summary`,
  `/invoices/invoice-generate`,
];

const InvoicesTabs = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);

  const { setTopPadding } = useHeader();
  useEffect(() => {
    setTopPadding(false);

    return () => {
      setTopPadding(true);
    };
  }, [setTopPadding]);

  return (
    <>
      {location.pathname === "/invoices" && <Redirect to={`/invoices/invoices-list`} />}
      <Tabs
        scrollButtons="auto"
        variant="scrollable"
        value={location.pathname}
        indicatorColor="primary"
        textColor="primary"
        style={{ backgroundColor: "rgba(255, 255, 255, 0.6)", paddingLeft: 24, paddingRight: 24 }}
      >
        {hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
          "INVOICE_ADMIN",
          "INVOICE_GET",
        ]) && (
          <Tab
            label={t("invoices.tabs.invoices")}
            component={Link}
            to={INVOICES_TABS[0]}
            value={INVOICES_TABS[0]}
          />
        )}
        {hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
          "INCOMING_INVOICE_ADMIN",
          "INCOMING_INVOICE_GET",
        ]) && (
          <Tab
            label={t("invoices.tabs.incoming")}
            component={Link}
            to={INVOICES_TABS[1]}
            value={INVOICES_TABS[1]}
          />
        )}
        {selectedRelTenant.tenant.isUseDefault &&
          hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
            "CASH_REGISTER_GET",
          ]) && (
            <Tab
              label={t("drawer./invoices/cash-register")}
              component={Link}
              to={INVOICES_TABS[2]}
              value={INVOICES_TABS[2]}
            />
          )}
        {hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
          "INVOICE_ADMIN",
        ]) && (
          <Tab
            label={t("invoiceTransaction.title")}
            component={Link}
            to={INVOICES_TABS[3]}
            value={INVOICES_TABS[3]}
          />
        )}
        {hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
          "INVOICE_ADMIN",
        ]) && (
          <Tab
            label={t("invoiceTransaction.summary.title")}
            component={Link}
            to={INVOICES_TABS[4]}
            value={INVOICES_TABS[4]}
          />
        )}
        {hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
          "INVOICE_ADMIN",
        ]) && (
          <Tab
            label={t("invoices.tabs.invoiceGenerate")}
            component={Link}
            to={INVOICES_TABS[5]}
            value={INVOICES_TABS[5]}
          />
        )}
      </Tabs>
      <Container maxWidth="xl">
        <Box pt={2}>
          <Switch location={location}>
            <PrivateRoute
              path={INVOICES_TABS[0]}
              component={TenantAllInvoice}
              permissions={["INVOICE_ADMIN", "INVOICE_GET"]}
            />
            <PrivateRoute
              path={INVOICES_TABS[1]}
              component={IncomingInvoiceList}
              permissions={["INCOMING_INVOICE_ADMIN", "INCOMING_INVOICE_GET"]}
            />
            {selectedRelTenant.tenant.isUseDefault && (
              <PrivateRoute
                path={INVOICES_TABS[2]}
                permissions={["CASH_REGISTER_GET"]}
                component={CashRegisterList}
              />
            )}
            <PrivateRoute
              path={INVOICES_TABS[3]}
              component={InvoiceTransactionList}
              permissions={["INVOICE_ADMIN"]}
            />
            <PrivateRoute
              path={INVOICES_TABS[4]}
              component={InvoiceTransactionSummary}
              permissions={["INVOICE_ADMIN"]}
            />
            <PrivateRoute
              path={INVOICES_TABS[5]}
              component={InvoiceGenerate}
              permissions={["INVOICE_ADMIN"]}
            />
          </Switch>
        </Box>
      </Container>
    </>
  );
};

export default InvoicesTabs;
