import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import ItemCategoriesContent from "../ItemCategories/components/ItemCategoriesContent";

type Props = {
  createOpen: boolean;
  setCreateOpen: Dispatch<SetStateAction<boolean>>;
};

const CategoryCreateDialog = ({ createOpen, setCreateOpen }: Props) => {
  const { t } = useTranslation();

  return (
    <Dialog open={createOpen} maxWidth="sm" fullWidth onClose={() => setCreateOpen(false)}>
      <DialogTitle disableTypography style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h6">{t("item.category.settings")}</Typography>
        <IconButton size="small" onClick={() => setCreateOpen(false)}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ padding: 16 }}>
        <ItemCategoriesContent />
      </DialogContent>
    </Dialog>
  );
};
export default CategoryCreateDialog;
