import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { RootState } from "config/store";
import { Dispatch, SetStateAction } from "react";
import { Controller, useForm, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { hasAuthority } from "shared/authorization";
import { getAllCategories } from "shared/network/category.api";
import { addItemCategory } from "shared/network/item.api";
import { ItemFormValues } from "./ItemForm";

type Props = {
  item: any;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  selectedCategories: any;
  setSelectedCategories: Dispatch<SetStateAction<any>>;
  setCreateOpen: Dispatch<SetStateAction<boolean>>;
};

const ItemCategoryDialog = ({
  item,
  open,
  setOpen,
  selectedCategories,
  setSelectedCategories,
  setCreateOpen,
}: Props) => {
  const { t } = useTranslation();
  const account = useSelector((state: RootState) => state.authentication?.account);
  const selectedRelTenant = useSelector(
    (state: RootState) => state.authentication?.selectedRelTenant,
  );
  const tenant = selectedRelTenant?.tenant;
  const { setValue } = useFormContext<ItemFormValues>();
  const { control: categoryControl, getValues } = useForm<{ categoryId: number | string }>();

  const categoryListQuery = useQuery(["caregoryListQuery", tenant.id], async () => {
    const { data } = await getAllCategories(tenant.id);
    return data.items?.sort((a, b) => a?.name?.localeCompare(b?.name));
  });

  const onSubmitAddCategory = async (id: number | string = getValues("categoryId")) => {
    if (id) {
      let item = categoryListQuery.data?.find(category => category?.id === id);
      if (item) {
        selectedCategories.push(item);
        setSelectedCategories(selectedCategories);
      }
      if (item?.parentId != null) {
        onSubmitAddCategory(item.parentId);
      }
    }
    if (item) {
      try {
        await addItemCategory(Number.parseInt(id.toString()), item.id.toString(), tenant.id);
      } catch {}
    }
    setValue("categories", selectedCategories);
  };

  return (
    <>
      <Dialog open={open} maxWidth="sm" fullWidth onClose={() => setOpen(false)}>
        <DialogTitle>{t("item.category.add")}</DialogTitle>
        <DialogContent>
          <Typography style={{ marginBottom: 16 }}>{t("item.category.addWarning")}</Typography>
          <form id="category_form" action="/">
            <Controller
              control={categoryControl}
              name="categoryId"
              defaultValue=""
              rules={{ required: t("validation.required").toString() }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  select
                  label={t("item.form.category")}
                  InputLabelProps={{ shrink: true }}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                >
                  <MenuItem value="" disabled>
                    <>{t("common:choose")}</>
                  </MenuItem>
                  {categoryListQuery.data?.map(item => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </form>
          <Box textAlign="center" marginTop="16px">
            <Button variant="outlined" onClick={() => setCreateOpen(true)}>
              {t("item.category.settings")}
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box display="flex" gridGap={8}>
            <Button onClick={() => setOpen(false)} variant="text">
              {t("common:button.cancel")}
            </Button>
            {hasAuthority(account.user, account.permissions, selectedRelTenant, [
              "COMPANY_ADMIN",
            ]) && (
              <Button
                variant="contained"
                onClick={() => {
                  setOpen(false);
                  onSubmitAddCategory();
                }}
              >
                {t("common:button.add")}
              </Button>
            )}
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ItemCategoryDialog;
