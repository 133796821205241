import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import { Check, Close } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { RootState } from "config/store";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useState } from "react";
import { Controller, FieldArrayMethodProps, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import QrReader from "react-qr-reader";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { getItemById } from "shared/network/item.api";

type Props = {
  openScanner: boolean;
  setOpenScanner: Dispatch<SetStateAction<boolean>>;
  append: (
    value: Partial<unknown> | Partial<unknown>[],
    options?: FieldArrayMethodProps | undefined,
  ) => void;
  currency?: any;
};

type QrCodeContent = {
  id: number;
  name: string;
  productCode: string;
};

type FormValues = {
  quantity: number;
};

const ItemQrReader = ({ openScanner, setOpenScanner, append, currency }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [scannedData, setScannedData] = useState<QrCodeContent | null>(null);
  const [alertData, setAlertData] = useState<string | null>(null);

  const tenant = useSelector((state: RootState) => state.authentication?.selectedRelTenant?.tenant);
  const form = useForm<FormValues>();

  const itemQuery = useQuery(
    ["itemQuery", scannedData?.id, tenant?.id],
    async () => {
      if (scannedData?.id) {
        const { data } = await getItemById(tenant?.id, scannedData.id.toString());
        return data?.item;
      }
      return Promise.reject();
    },
    { enabled: !!scannedData?.id },
  );

  const handleScan = (data: string | null) => {
    if (data) {
      try {
        const parsedData: QrCodeContent = JSON.parse(data);
        if (parsedData) {
          setScannedData(parsedData);
        }
      } catch (error: any) {
        setAlertData(t("qrCode.error"));
      }
    }
  };

  function saveAndContinue(values: FormValues) {
    if (itemQuery?.data) {
      const itemPrice =
        itemQuery?.data?.itemPriceHistories?.find(
          price => price.isActive && price?.currency?.id === currency?.id,
        )?.price || 0;
      const itemTax =
        itemQuery?.data?.itemTaxHistories?.find(price => price.isActive)?.tax?.percent || 27;
      append({
        item: itemQuery?.data,
        quantity: values.quantity,
        netPrice: Number(itemPrice) * values.quantity,
        tax: itemTax,
      });
      setScannedData(null);
      form.setValue("quantity", 1);
    }
  }
  function save(values: FormValues) {
    if (itemQuery?.data) {
      const itemPrice =
        itemQuery?.data?.itemPriceHistories?.find(
          price => price.isActive && price?.currency?.id === currency?.id,
        )?.price || 0;
      const itemTax =
        itemQuery?.data?.itemTaxHistories?.find(price => price.isActive)?.tax?.percent || 27;

      append({
        item: itemQuery?.data,
        quantity: values.quantity,
        netPrice: Number(itemPrice) * values.quantity,
        tax: itemTax,
      });
      setOpenScanner(false);
      setScannedData(null);
      form.setValue("quantity", 1);
    }
  }

  function onClose() {
    setOpenScanner(false);
    setScannedData(null);
    form.setValue("quantity", 1);
  }

  return (
    <Dialog fullWidth maxWidth="sm" open={openScanner} onClose={() => onClose()}>
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <span>{t("qrCode.title")}</span>
          <IconButton size="small" onClick={() => onClose()}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent style={{ paddingTop: 0 }}>
        {alertData ? (
          <Box display="flex" flexDirection="column" gridGap={16}>
            <Alert severity="warning" variant="filled" style={{ borderRadius: 10 }}>
              {alertData}
            </Alert>
            <Button onClick={() => setAlertData(null)} style={{ marginBottom: 8 }}>
              {t("qrCode.newQrcode")}
            </Button>
          </Box>
        ) : scannedData ? (
          <Box>
            {!!itemQuery.error && (
              <Box display="flex" flexDirection="column" gridGap={16}>
                <Alert
                  severity="warning"
                  variant="filled"
                  style={{ borderRadius: 10, marginBottom: 8 }}
                >
                  {t("qrCode.notFound")}
                </Alert>
                <Button
                  onClick={() => {
                    setScannedData(null);
                    form.setValue("quantity", 1);
                  }}
                  style={{ marginBottom: 8 }}
                >
                  {t("qrCode.newQrcode")}
                </Button>
              </Box>
            )}
            {!!itemQuery.isFetching && (
              <Box display="flex" justifyContent="center" width="100%" p={3}>
                <CircularProgress />
              </Box>
            )}
            {!!itemQuery.data && (
              <Grid container alignItems="center" spacing={1}>
                <Grid item xs={12}>
                  <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
                    {t("qrCode.item")}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Typography style={{ fontWeight: "bold" }}>{t("qrCode.name")}</Typography>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Typography>{itemQuery.data?.name}</Typography>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Typography style={{ fontWeight: "bold" }}>{t("qrCode.productCode")}</Typography>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Typography>{itemQuery.data?.productCode}</Typography>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Typography style={{ fontWeight: "bold" }}>
                    {t("warehouse.transaction.formValues.quantity")}:
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Controller
                    control={form.control}
                    name={`quantity`}
                    defaultValue={1}
                    rules={{
                      required: t("validation.required").toString(),
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="number"
                        inputProps={{ step: "0.01", min: "0.01" }}
                        label={t("warehouse.transaction.formValues.quantity")}
                        InputLabelProps={{ shrink: true, required: true }}
                        style={{ maxWidth: 150 }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    flexWrap={"wrap"}
                    gridGap={8}
                    width="100%"
                  >
                    <Button
                      startIcon={<Check />}
                      onClick={form.handleSubmit(save)}
                      disabled={!itemQuery.data}
                    >
                      {t("common:button.save")}
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={form.handleSubmit(saveAndContinue)}
                      disabled={!itemQuery.data}
                    >
                      {t("qrCode.continue")}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            )}
          </Box>
        ) : (
          <Box display="flex" justifyContent="center" alignItems="center" height="100%" pb={5}>
            <Box width="100%">
              <QrReader
                onError={() => enqueueSnackbar(t("qrCode.access"), { variant: "warning" })}
                onScan={handleScan}
              />
            </Box>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ItemQrReader;
