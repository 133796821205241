import {
  Box,
  Button,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { AddBox, Close, Info } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import FormCard from "components/FormCard";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { useEffect } from "react";
import {
  Controller,
  FieldArrayMethodProps,
  FieldArrayWithId,
  useFormContext,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { listTools } from "shared/network/tools.api";
import { Issue, Tool } from "shared/types";
import { IssueFromValues } from "../IssueCreate";

const useStyles = makeStyles({
  empty: {
    fontSize: 20,
    width: "100%",
  },
});

type Props = {
  issue?: Issue;
  append: (
    value:
      | Partial<{
          tool: Tool | null;
        }>
      | Partial<{
          tool: Tool | null;
        }>[],
    options?: FieldArrayMethodProps | undefined,
  ) => void;
  fields: FieldArrayWithId<IssueFromValues, "tools", "key">[];
  remove: (index?: number | number[] | undefined) => void;
  replace: (value: Partial<{ tool: Tool | null }> | Partial<{ tool: Tool | null }>[]) => void;
  isIssueTemplateChanged: boolean;
};

const ToolCard = ({ issue, append, fields, remove, replace, isIssueTemplateChanged }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { control, watch } = useFormContext<IssueFromValues>();
  const selectedIssueTemplate = watch("issueTemplate");

  const tenant = useSelector(
    (state: RootState) => state?.authentication?.selectedRelTenant?.tenant,
  );

  const toolListQuery = useQuery(["toolList", tenant.id], async () => {
    const { data } = await listTools(0, 1000000, tenant.id, "");
    return data.page;
  });

  useEffect(() => {
    const toolListData: { tool: Tool | null }[] = [];
    issue?.tools?.forEach(tool => {
      toolListData.push({
        tool: tool.tool,
      });
    });
    if (isIssueTemplateChanged && selectedIssueTemplate) {
      selectedIssueTemplate.relIssueTemplateTools?.forEach(tool => {
        toolListData.push({
          tool: tool.tool,
        });
      });
    }
    replace(toolListData);
  }, [issue, selectedIssueTemplate, replace]); //eslint-disable-line

  return (
    <FormCard title={t("tool.title")}>
      {fields.length ? (
        fields.map((field, index) => (
          <Grid key={field.key} container spacing={2} alignItems="center">
            <Grid item xs={6}>
              <Typography variant="body2" style={{ paddingLeft: 8 }}>
                {t("tool.formValues.toolListIndex", {
                  index: index + 1,
                })}
              </Typography>
            </Grid>
            <Grid item xs={6} style={{ textAlign: "right" }}>
              <Tooltip title={t("common:button.delete").toString()}>
                <IconButton size="small" onClick={() => remove(index)}>
                  <Close fontSize="small" />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name={`tools.${index}.tool`}
                rules={{
                  required: t("validation.required").toString(),
                }}
                defaultValue={issue?.tools?.[index]?.tool || null}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    style={{ marginBottom: 8 }}
                    options={toolListQuery?.data?.content || []}
                    getOptionLabel={(option: Tool) => {
                      if (option.name && option.serialNumber) {
                        return `${option.name} (${option.serialNumber})`;
                      }
                      return "";
                    }}
                    getOptionSelected={option => option.id === field.value?.id}
                    onChange={(_, value) => field.onChange(value)}
                    renderInput={params => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <Tooltip
                              style={{
                                paddingRight: "2px",
                              }}
                              title={t("tooltip.toolName").toString()}
                            >
                              <Info style={{ color: COLORS.lightBlue }} />
                            </Tooltip>
                          ),
                        }}
                        label={t("tool.formValues.nameOrSerialNumber")}
                        InputLabelProps={{
                          shrink: true,
                          required: true,
                        }}
                      />
                    )}
                  />
                )}
              />
            </Grid>
          </Grid>
        ))
      ) : (
        <Grid item container xs={12} justifyContent="center">
          <Box textAlign="center">
            <Typography className={classes.empty} color="secondary">
              {t("common:emptyToolList")}
            </Typography>
          </Box>
        </Grid>
      )}
      <Box display="flex" justifyContent="center" width="100%">
        <Button
          variant="outlined"
          color="primary"
          style={{ marginBottom: 8, marginTop: 16 }}
          onClick={event => {
            event.stopPropagation();
            append({});
          }}
          startIcon={<AddBox />}
        >
          {t("tool.add")}
        </Button>
      </Box>
    </FormCard>
  );
};

export default ToolCard;
