import { Box, Container, Tab, Tabs } from "@material-ui/core";
import PrivateRoute from "components/PrivateRoute";
import { RootState } from "config/store";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Link, Redirect, Switch } from "react-router-dom";
import PropertiesList from "views/Properties/PropertiesList";
import ListArticle from "./Article/ListArticle";
import AuditLog from "./AuditLog/AuditLog";
import BundleList from "./Bundles/BundleList";
import CompanyCategoryList from "./CompanyCategory/CompanyCategoryList";
import CurrencyList from "./Currency/CurrencyList";
import ExpenseIdentifierSearch from "./ExpenseIdentifier/ExpenseIdentifierSearch";
import PackagingList from "./Packaging/PackagingList";
import PermissionGroupList from "./PermissionGroup/PermissionGroupList";
import TaxTypeList from "./TaxType/TaxTypeList";
import TenantList from "./Tenants/TenantList";
import { useHeader } from "components/Layout/HeaderContext";
import { useEffect } from "react";
import CustomNotificationList from "./CustomNotification/CustomNotificationList";
import SentEmails from "./EmailList/SentEmails";

export const ADMIN_TABS = [
  "/admin/bundles",
  "/admin/tenants",
  "/admin/currencies",
  "/admin/sent-emails",
  "/admin/email-templates",
  "/admin/permission-groups",
  "/admin/company-category",
  "/admin/audit-log",
  "/admin/properties",
  "/admin/articles",
  "/admin/packaging",
  "/admin/expense-identifier",
  "/admin/tax-types",
];

const AdminTabs = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const { setTopPadding } = useHeader();
  useEffect(() => {
    setTopPadding(false);

    return () => {
      setTopPadding(true);
    };
  }, [setTopPadding]);
  return (
    <>
      {location.pathname === "/admin" && <Redirect to="/admin/bundles" />}
      <Tabs
        scrollButtons="auto"
        variant="scrollable"
        value={location.pathname}
        indicatorColor="primary"
        textColor="primary"
        style={{ backgroundColor: "rgba(255, 255, 255, 0.6)", paddingLeft: 24, paddingRight: 24 }}
      >
        {account?.user?.isSuperAdmin && (
          <Tab
            label={t("bundle.title")}
            component={Link}
            to={ADMIN_TABS[0]}
            value={ADMIN_TABS[0]}
          />
        )}
        {account?.user?.isSuperAdmin && (
          <Tab
            label={t("tenant.title")}
            component={Link}
            to={ADMIN_TABS[1]}
            value={ADMIN_TABS[1]}
          />
        )}
        {(account?.user?.isSuperAdmin || selectedRelTenant?.isTenantAdmin) && (
          <Tab
            label={t("currency.title")}
            component={Link}
            to={ADMIN_TABS[2]}
            value={ADMIN_TABS[2]}
          />
        )}
        {account?.user?.isSuperAdmin && (
          <Tab
            label={t("drawer./admin/sent-emails")}
            component={Link}
            to={ADMIN_TABS[3]}
            value={ADMIN_TABS[3]}
          />
        )}
        {(account?.user?.isSuperAdmin || selectedRelTenant?.isTenantAdmin) && (
          <Tab
            label={t("drawer./admin/email-templates")}
            component={Link}
            to={ADMIN_TABS[4]}
            value={ADMIN_TABS[4]}
          />
        )}
        {(account?.user?.isSuperAdmin || selectedRelTenant?.isTenantAdmin) && (
          <Tab
            label={t("permissionGroup.title")}
            component={Link}
            to={ADMIN_TABS[5]}
            value={ADMIN_TABS[5]}
          />
        )}
        {(account?.user?.isSuperAdmin || selectedRelTenant?.isTenantAdmin) && (
          <Tab
            label={t("companyCategory.title")}
            component={Link}
            to={ADMIN_TABS[6]}
            value={ADMIN_TABS[6]}
          />
        )}
        {account?.user?.isSuperAdmin && (
          <Tab
            label={t("auditLog.title")}
            component={Link}
            to={ADMIN_TABS[7]}
            value={ADMIN_TABS[7]}
          />
        )}
        {account.user.isSuperAdmin && (
          <Tab
            label={t("property.title")}
            component={Link}
            to={ADMIN_TABS[8]}
            value={ADMIN_TABS[8]}
          />
        )}
        {(account?.user?.isSuperAdmin || selectedRelTenant?.isTenantAdmin) && (
          <Tab
            label={t("article.title")}
            component={Link}
            to={ADMIN_TABS[9]}
            value={ADMIN_TABS[9]}
          />
        )}
        {(account?.user?.isSuperAdmin || selectedRelTenant?.isTenantAdmin) && (
          <Tab
            label={t("packaging.title")}
            component={Link}
            to={ADMIN_TABS[10]}
            value={ADMIN_TABS[10]}
          />
        )}
        {(account?.user?.isSuperAdmin || selectedRelTenant?.isTenantAdmin) && (
          <Tab
            label={t("packaging.expenseIdentifier")}
            component={Link}
            to={ADMIN_TABS[11]}
            value={ADMIN_TABS[11]}
          />
        )}
        {(account?.user?.isSuperAdmin || selectedRelTenant?.isTenantAdmin) && (
          <Tab
            label={t("taxType.tabTitle")}
            component={Link}
            to={ADMIN_TABS[12]}
            value={ADMIN_TABS[12]}
          />
        )}
      </Tabs>
      <Container maxWidth="xl">
        <Box pt={2}>
          <Switch location={location}>
            {account?.user?.isSuperAdmin && (
              <PrivateRoute path={ADMIN_TABS[0]} component={BundleList} />
            )}
            {account?.user?.isSuperAdmin && (
              <PrivateRoute path={ADMIN_TABS[1]} component={TenantList} />
            )}
            {(account?.user?.isSuperAdmin || selectedRelTenant?.isTenantAdmin) && (
              <PrivateRoute path={ADMIN_TABS[2]} component={CurrencyList} />
            )}
            {account?.user?.isSuperAdmin && (
              <PrivateRoute path={ADMIN_TABS[3]} component={SentEmails} />
            )}
            {(account?.user?.isSuperAdmin || selectedRelTenant?.isTenantAdmin) && (
              <PrivateRoute path={ADMIN_TABS[4]} component={CustomNotificationList} />
            )}
            {(account?.user?.isSuperAdmin || selectedRelTenant?.isTenantAdmin) && (
              <PrivateRoute path={ADMIN_TABS[5]} component={PermissionGroupList} />
            )}
            {(account?.user?.isSuperAdmin || selectedRelTenant?.isTenantAdmin) && (
              <PrivateRoute path={ADMIN_TABS[6]} component={CompanyCategoryList} />
            )}
            {account?.user?.isSuperAdmin && (
              <PrivateRoute path={ADMIN_TABS[7]} component={AuditLog} />
            )}
            {account.user.isSuperAdmin && (
              <PrivateRoute path={ADMIN_TABS[8]} component={PropertiesList} />
            )}
            {(account?.user?.isSuperAdmin || selectedRelTenant?.isTenantAdmin) && (
              <PrivateRoute path={ADMIN_TABS[9]} component={ListArticle} />
            )}
            {(account?.user?.isSuperAdmin || selectedRelTenant?.isTenantAdmin) && (
              <PrivateRoute path={ADMIN_TABS[10]} component={PackagingList} />
            )}
            {(account?.user?.isSuperAdmin || selectedRelTenant?.isTenantAdmin) && (
              <PrivateRoute path={ADMIN_TABS[11]} component={ExpenseIdentifierSearch} />
            )}
            {(account?.user?.isSuperAdmin || selectedRelTenant?.isTenantAdmin) && (
              <PrivateRoute path={ADMIN_TABS[12]} component={TaxTypeList} />
            )}
          </Switch>
        </Box>
      </Container>
    </>
  );
};
export default AdminTabs;
