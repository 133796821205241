import axios from "config/axios";

const ENDPOINT = "/rel-issue-custom-fields";

export const createRelIssueCustomFields = (
  params: {
    issueId: number;
    relIssueCustomFieldsEntries: {
      id?: string;
      tenantId: number;
      issueId: number;
      customField: {
        id: string;
      };
      value: string;
      orderNumber: number;
    }[];
  },
  tenantId: number
) => axios.post(`${ENDPOINT}/create`, { ...params, tenantId });
