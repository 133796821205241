import { Box, Grid, IconButton, TextField, Tooltip, Typography } from "@material-ui/core";
import { Close, Info } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import NumberField from "components/NumberField";
import {
  NUMBER_FORMAT,
  PAGEABLE_AUTOCOMPLETE_CALLBACK_DELAY,
  PAGEABLE_AUTOCOMPLETE_MIN_STRING_LENGTH,
} from "config/constants";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { listItems } from "shared/network/item.api";
import { listSerialItems } from "shared/network/serial-item.api";
import { listWarehousePageable } from "shared/network/warehouse.api";
import { Item, SerialItemEntry, Warehouse } from "shared/types";
import { useDebouncedCallback } from "use-debounce";
import { IssueFromValues } from "../IssueCreate";

type Props = {
  field: any;
  index: number;
  remove: (index?: number | number[] | undefined) => void;
};

const ItemRow = ({ field, index, remove }: Props) => {
  const { t } = useTranslation();
  const { control, watch, setValue } = useFormContext<IssueFromValues>();
  const { tenant } = useSelector((state: RootState) => state.authentication.selectedRelTenant);
  const item = watch(`items.${index}.item`);
  const location = useLocation();
  const [itemSearch, setItemSearch] = useState<string>("");
  const [serialItemSearch, setSerialItemSearch] = useState<string>("");
  const [warehouseSearch, setWarehouseSearch] = useState<string>("");

  const itemListQuery = useQuery(["issueItemList", tenant.id, itemSearch], async () => {
    const { data } = await listItems(
      0,
      10,
      tenant.id,
      (itemSearch ? `(name:${itemSearch};(OR)productCode:${itemSearch};)` : "") +
        "purchasable:true",
    );
    return data.page;
  });

  const avaibleSerialItemList = useQuery(
    ["avaibleSerialItemListItemCard", tenant.id, item, serialItemSearch],
    async () => {
      const { data } = await listSerialItems(
        0,
        10,
        tenant.id,
        (serialItemSearch ? `factoryNumber:$${serialItemSearch};` : "") + `item.id:${item?.id}`,
      );
      return data.page.content;
    },
    { enabled: !!item?.name },
  );

  const warehouseListQuery = useQuery(
    ["warehouseListQuery", tenant.id, warehouseSearch],
    async () => {
      const { data } = await listWarehousePageable(
        0,
        10,
        tenant.id,
        warehouseSearch ? `name:${warehouseSearch}` : "",
      );
      return data.page.content;
    },
  );

  const handleSearchStringChange = useDebouncedCallback((value: string, type: string) => {
    if (value.length >= PAGEABLE_AUTOCOMPLETE_MIN_STRING_LENGTH || value.length === 0) {
      switch (type) {
        case "ITEM":
          setItemSearch(value);
          break;
        case "SERIAL_ITEM":
          setSerialItemSearch(value);
          break;
        case "WAREHOUSE":
          setWarehouseSearch(value);
          break;
      }
    }
  }, PAGEABLE_AUTOCOMPLETE_CALLBACK_DELAY);

  useEffect(() => {
    setValue(`items.${index}.item`, field?.item || null);
    setValue(`items.${index}.itemSerial`, field?.itemSerial || null);
  }, [field?.item]); //eslint-disable-line

  return (
    <Box key={index} display="flex" mb={1}>
      <Box display="flex" justifyContent="center" alignItems="center" width={40} mb={1}>
        <Typography variant="h3">{index + 1}.</Typography>
      </Box>
      <Grid key={field.key} container spacing={2} alignItems="center">
        <Grid item xs={6}>
          <Controller
            control={control}
            name={`items.${index}.item`}
            defaultValue={field?.item || null}
            rules={{
              required: t("validation.required").toString(),
            }}
            render={({ field }) => (
              <Autocomplete
                {...field}
                style={{ marginBottom: 8 }}
                options={itemListQuery?.data?.content || []}
                getOptionSelected={option => option.id === field.value?.id}
                getOptionLabel={(option: Item) => {
                  if (option.name && option.productCode) {
                    return `${option.name} (${option.productCode})`;
                  }
                  return "";
                }}
                onChange={(_, value) => {
                  field.onChange(value);
                  handleSearchStringChange("", "ITEM");

                  setValue(`items.${index}.itemSerial`, value?.itemSerial);
                }}
                onInputChange={(event, newInputValue) => {
                  handleSearchStringChange(newInputValue, "ITEM");
                }}
                loading={itemListQuery.isFetching}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={t("item.part")}
                    InputLabelProps={{
                      shrink: true,
                      required: true,
                    }}
                  />
                )}
              />
            )}
          />
        </Grid>
        {item?.type !== "SERIAL_NUMBER" && (
          <>
            <Grid item xs={3}>
              <Controller
                control={control}
                name={`items.${index}.quantity`}
                defaultValue={(field as any).quantity || "1"}
                rules={{
                  required: t("validation.required").toString(),
                  validate: value => {
                    if (value && !value.toString().match(NUMBER_FORMAT)) {
                      return t("common:validation.numberFormat").toString();
                    }
                  },
                }}
                render={({ field, fieldState }) => (
                  <NumberField
                    field={field}
                    fieldState={fieldState}
                    label={t("item.quantity")}
                    style={{ marginBottom: 8 }}
                    InputLabelProps={{
                      shrink: true,
                      required: true,
                    }}
                    numberLimits={{ step: "0.01", min: "0.01" }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                control={control}
                name={`items.${index}.fromWarehouse`}
                defaultValue={field?.fromWarehouse || null}
                render={({ field, fieldState }) => (
                  <Autocomplete
                    {...field}
                    onChange={(_, value) => {
                      field.onChange(value);
                      handleSearchStringChange("", "WAREHOUSE");
                    }}
                    onInputChange={(event, newInputValue) => {
                      handleSearchStringChange(newInputValue, "WAREHOUSE");
                    }}
                    options={warehouseListQuery.data || []}
                    loading={warehouseListQuery.isFetching}
                    getOptionLabel={(option: Warehouse) => option.name}
                    getOptionSelected={option => option.id === field.value?.id}
                    renderInput={params => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <Tooltip
                              style={{
                                paddingRight: "2px",
                              }}
                              title={t("tooltip.warehouseName").toString()}
                            >
                              <Info style={{ color: COLORS.lightBlue }} />
                            </Tooltip>
                          ),
                        }}
                        style={{ marginBottom: 8 }}
                        InputLabelProps={{ shrink: true }}
                        label={t("issues.formValues.warehouse")}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>
          </>
        )}
        {item?.type === "SERIAL_NUMBER" && (
          <>
            <Grid item xs={3}>
              <Controller
                control={control}
                name={`items.${index}.itemSerial`}
                rules={{
                  required: t("validation.required").toString(),
                }}
                defaultValue={field.itemSerial || null}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    onChange={(_, value) => {
                      field.onChange(value);
                      handleSearchStringChange("", "SERIAL_ITEM");
                    }}
                    onInputChange={(event, newInputValue) => {
                      handleSearchStringChange(newInputValue, "SERIAL_ITEM");
                    }}
                    options={avaibleSerialItemList.data || []}
                    loading={avaibleSerialItemList.isFetching}
                    getOptionLabel={(option: SerialItemEntry) => option.factoryNumber}
                    getOptionSelected={option => option.id === field.value?.id}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label={t("cargo.formValues.serialNumber")}
                        style={{ marginBottom: 8 }}
                        InputLabelProps={{
                          shrink: true,
                          required: true,
                        }}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            {location.state === "SCH_BANK" && (
              <>
                <Grid item xs={3}>
                  <Controller
                    control={control}
                    name={`items.${index}.totalOperatingHours`}
                    defaultValue={field.totalOperatingHours || null}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        style={{ marginBottom: 8 }}
                        label={t("issues.worksheet.totalOperatingHours")}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    control={control}
                    name={`items.${index}.activeDeliveryTime`}
                    defaultValue={field.activeDeliveryTime || null}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={t("issues.worksheet.activeDeliveryTime")}
                        style={{ marginBottom: 8 }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    control={control}
                    name={`items.${index}.passiveDeliveryTime`}
                    defaultValue={field.passiveDeliveryTime || null}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={t("issues.worksheet.passiveDeliveryTime")}
                        style={{ marginBottom: 8 }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    control={control}
                    name={`items.${index}.numberOfBanknotes`}
                    defaultValue={field.numberOfBanknotes || null}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={t("issues.worksheet.numberOfBanknotes")}
                        style={{ marginBottom: 8 }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          startAdornment: (
                            <Tooltip
                              style={{
                                paddingRight: "2px",
                              }}
                              title={t("issues.worksheet.banknoteInformation").toString()}
                            >
                              <Info style={{ color: COLORS.lightBlue }} />
                            </Tooltip>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
      <Box display="flex">
        <Box display="flex" justifyContent="center" alignItems="center" width={40} pl={1} mb={1}>
          <Tooltip title={t("common:button.delete").toString()}>
            <IconButton size="small" onClick={() => remove(index)}>
              <Close />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </Box>
  );
};

export default ItemRow;
