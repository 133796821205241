import { Grid } from "@material-ui/core";
import FormCard from "components/FormCard";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Issue } from "shared/types";
import { IssueFromValues } from "../IssueCreate";
import CustomField from "./CustomField";

type Props = {
  issue?: Issue;
};

const CostCard = ({ issue }: Props) => {
  const { t } = useTranslation();
  const { watch } = useFormContext<IssueFromValues>();
  const selectedIssueTemplate = watch("issueTemplate");

  return (
    <FormCard title={t("issues.worksheet.cost.title")}>
      <Grid container spacing={2}>
        {selectedIssueTemplate?.relIssueTemplateCustomFields
          ?.filter(relCustomField => relCustomField.customField.name === "Napidíj")
          .map((relCustomField, index) => {
            const htmlAttributes = JSON.parse(relCustomField.customField.htmlAttributes);
            return (
              <CustomField
                name={relCustomField.customField.id}
                defaultValue={relCustomField.defaultValue}
                htmlAttributes={htmlAttributes}
                type={relCustomField.customField.type}
                index={index}
                label={relCustomField.customField.name}
                fieldValues={relCustomField?.customField?.fieldValues}
              />
            );
          })}
        {selectedIssueTemplate?.relIssueTemplateCustomFields
          ?.filter(relCustomField => relCustomField.customField.name === "Szállás")
          .map((relCustomField, index) => {
            const htmlAttributes = JSON.parse(relCustomField.customField.htmlAttributes);
            return (
              <CustomField
                name={relCustomField.customField.id}
                defaultValue={relCustomField.defaultValue}
                htmlAttributes={htmlAttributes}
                type={relCustomField.customField.type}
                index={index}
                label={relCustomField.customField.name}
                fieldValues={relCustomField?.customField?.fieldValues}
              />
            );
          })}
        {selectedIssueTemplate?.relIssueTemplateCustomFields
          ?.filter(relCustomField => relCustomField.customField.name === "Utazás / megtett km")
          .map((relCustomField, index) => {
            const htmlAttributes = JSON.parse(relCustomField.customField.htmlAttributes);
            return (
              <CustomField
                name={relCustomField.customField.id}
                defaultValue={relCustomField.defaultValue}
                htmlAttributes={htmlAttributes}
                type={relCustomField.customField.type}
                index={index}
                label={relCustomField.customField.name}
                fieldValues={relCustomField?.customField?.fieldValues}
              />
            );
          })}
      </Grid>
    </FormCard>
  );
};

export default CostCard;
