import { Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import FormCard from "components/FormCard";
import {
  PAGEABLE_AUTOCOMPLETE_CALLBACK_DELAY,
  PAGEABLE_AUTOCOMPLETE_MIN_STRING_LENGTH,
} from "config/constants";
import { RootState } from "config/store";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { listCompanyByTenant } from "shared/network/company.api";
import { Company, Issue } from "shared/types";
import { useDebouncedCallback } from "use-debounce";
import { IssueFromValues } from "../IssueCreate";
import CustomField from "./CustomField";

type Props = {
  issue?: Issue;
};

const ContactCard = ({ issue }: Props) => {
  const { t } = useTranslation();
  const { control, setValue, watch } = useFormContext<IssueFromValues>();
  //@ts-ignore
  const selectedIssueTemplate = watch("issueTemplate");
  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);

  const tenant = selectedRelTenant.tenant;

  const [companySearch, setCompanySearch] = useState<string>("");

  const handleCompanySearchChange = useDebouncedCallback((value: string) => {
    if (value.length >= PAGEABLE_AUTOCOMPLETE_MIN_STRING_LENGTH || value.length === 0) {
      setCompanySearch(value);
    }
  }, PAGEABLE_AUTOCOMPLETE_CALLBACK_DELAY);

  const companyListQuery = useQuery(
    ["issueCompanyListQuery", tenant.id, companySearch],
    async () => {
      const { data } = await listCompanyByTenant(
        0,
        10,
        tenant.id,
        companySearch ? `name:${companySearch}` : ``,
      );
      return data;
    },
  );

  return (
    <FormCard title={"Munkalap adatok"}>
      <Grid container spacing={2}>
        <Grid item sm={12} md={6}>
          <Controller
            control={control}
            name="company"
            defaultValue={issue?.company || account.user.relUserCompany?.[0]?.company || null}
            rules={{ required: t("validation.required").toString() }}
            render={({ field, fieldState }) => (
              <Autocomplete
                {...field}
                onChange={(_, value) => {
                  field.onChange(value);
                  setValue("project", null);
                  handleCompanySearchChange("");
                }}
                onInputChange={(event, newInputValue) => {
                  handleCompanySearchChange(newInputValue);
                }}
                options={companyListQuery.data?.page?.content || []}
                getOptionLabel={(option: Company) => option.name}
                getOptionSelected={option => option.id === field.value?.id}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={t("issues.formValues.company")}
                    InputLabelProps={{ shrink: true, required: true }}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            )}
          />
        </Grid>
        {selectedIssueTemplate?.relIssueTemplateCustomFields
          ?.filter(relCustomField => relCustomField.customField.name === "A munka neme")
          .map((relCustomField, index) => {
            const htmlAttributes = JSON.parse(relCustomField.customField.htmlAttributes);
            return (
              <CustomField
                name={relCustomField.customField.id}
                defaultValue={relCustomField.defaultValue}
                htmlAttributes={htmlAttributes}
                type={relCustomField.customField.type}
                index={index}
                label={relCustomField.customField.name}
                fieldValues={relCustomField?.customField?.fieldValues}
              />
            );
          })}
        <Grid item xs={12}>
          <Controller
            control={control}
            name="description"
            defaultValue={issue?.description || ""}
            rules={{ required: t("validation.required").toString() }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                multiline
                minRows={4}
                label={t("issues.formValues.description")}
                InputLabelProps={{ shrink: true, required: true }}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Grid>
      </Grid>
    </FormCard>
  );
};

export default ContactCard;
