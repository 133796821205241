import { Box, Container, Tab, Tabs } from "@material-ui/core";
import PrivateRoute from "components/PrivateRoute";
import { RootState } from "config/store";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Link, Redirect, Switch } from "react-router-dom";
import { hasAnyAuthority } from "shared/authorization";
import CustomFields from "views/Issues/CustomFields/CustomFields";
import IssueTemplateList from "views/Issues/IssueTemplate/IssueTemplateList";
import CoworkerIssueList from "./CoworkerIssueList";
import IssueList from "./IssueList";
import IssueTypeList from "./IssueType/IssueTypeList";
import KanbanBoard from "./KanbanBoard/KanbanBoard";
import PersonalIssueList from "./PersonalIssueList";
import { useHeader } from "components/Layout/HeaderContext";
import { useEffect } from "react";

export const ISSUES_TABS = [
  "/issues/all",
  "/issues/personal",
  "/issues/templates",
  "/issues/issue-type",
  `/issues/custom-fields`,
  `/issues/kanban`,
];

const IssuesTabs = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const { setTopPadding } = useHeader();
  useEffect(() => {
    setTopPadding(false);

    return () => {
      setTopPadding(true);
    };
  }, [setTopPadding]);

  const isPartner =
    account.user.relUserCompany?.length !== 0 &&
    !account.user.isSuperAdmin &&
    !selectedRelTenant.isTenantAdmin;

  return (
    <>
      {location.pathname === "/issues" && <Redirect to="/issues/all" />}
      <Tabs
        scrollButtons="auto"
        variant="scrollable"
        value={location.pathname}
        indicatorColor="primary"
        textColor="primary"
        style={{ backgroundColor: "rgba(255, 255, 255, 0.6)", paddingLeft: 24, paddingRight: 24 }}
      >
        {hasAnyAuthority(account.user, account.permissions, selectedRelTenant, ["ISSUE_GET"]) && (
          <Tab
            label={
              account.user.isSuperAdmin || selectedRelTenant.isTenantAdmin || isPartner
                ? t("issues.allIssues")
                : t("issues.coworkerIssueList")
            }
            component={Link}
            to={ISSUES_TABS[0]}
            value={ISSUES_TABS[0]}
          />
        )}
        {!isPartner &&
          hasAnyAuthority(account.user, account.permissions, selectedRelTenant, ["ISSUE_GET"]) && (
            <Tab
              label={t("issues.personalIssues")}
              component={Link}
              to={ISSUES_TABS[1]}
              value={ISSUES_TABS[1]}
            />
          )}
        {!isPartner &&
          hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
            "ISSUE_TEMPLATE_GET",
          ]) && (
            <Tab
              label={t("issueTemplate.title")}
              component={Link}
              to={ISSUES_TABS[2]}
              value={ISSUES_TABS[2]}
            />
          )}
        {!isPartner &&
          hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
            "ISSUE_TYPE_GET",
          ]) && (
            <Tab
              label={t("issueType.title")}
              component={Link}
              to={ISSUES_TABS[3]}
              value={ISSUES_TABS[3]}
            />
          )}
        {!isPartner && isEmpty(account.user.relUserCompany) && (
          <Tab
            label={t("issues.customFields")}
            component={Link}
            to={ISSUES_TABS[4]}
            value={ISSUES_TABS[4]}
          />
        )}
        {!isPartner &&
          hasAnyAuthority(account.user, account.permissions, selectedRelTenant, ["ISSUE_GET"]) && (
            <Tab
              label={t("issues.kanban")}
              component={Link}
              to={ISSUES_TABS[5]}
              value={ISSUES_TABS[5]}
            />
          )}
      </Tabs>
      <Container maxWidth="xl">
        <Box pt={2}>
          <Switch location={location}>
            <PrivateRoute
              path={ISSUES_TABS[0]}
              component={
                account.user.isSuperAdmin || selectedRelTenant.isTenantAdmin || isPartner
                  ? IssueList
                  : CoworkerIssueList
              }
              permissions={["ISSUE_GET"]}
            />
            <PrivateRoute
              path={ISSUES_TABS[1]}
              component={PersonalIssueList}
              permissions={["ISSUE_GET"]}
            />

            <PrivateRoute
              path={ISSUES_TABS[2]}
              component={IssueTemplateList}
              permissions={["ISSUE_TEMPLATE_GET"]}
            />
            <PrivateRoute
              path={ISSUES_TABS[3]}
              component={IssueTypeList}
              permissions={["ISSUE_TYPE_GET"]}
            />
            <PrivateRoute path={ISSUES_TABS[4]} component={CustomFields} />
            <PrivateRoute
              path={ISSUES_TABS[5]}
              component={KanbanBoard}
              permissions={["ISSUE_GET"]}
            />
          </Switch>
        </Box>
      </Container>
    </>
  );
};

export default IssuesTabs;
