import axios from "config/axios";
import { GenericListResponse, GenericPageResponse, GenericResponse } from "shared/common";
import { BaseCurrency, Company, Issue, Offer, OfferHistoryEntry } from "shared/types";

const ENDPOINT = "/offer";

type OfferRequest = {
  tenantId: number;
  company: Company;
  name: string;
  status: string;
  type: string;
  description: string;
  validTo: string;
  discount: string;
  currency: BaseCurrency | null;
  relOfferItems: any[]; //RelOfferItemEntry[];
  projectTemplateId: number | string;
};

export type OfferModifyRequest = {
  id?: number;
  name?: string;
  company?: any; //Company
  identifier?: string;
  tenantId?: number;
  status?: string;
  description?: string;
  validTo?: string;
  discount?: number;
  currency?: any; //BaseCurrency
  type?: string;
  isIncoming?: boolean;
  relOfferItems?: any[]; //RelOfferItemEntry
  projectTemplateId: number | string;
};

export const createOffer = (offer: OfferRequest, issueList: Issue[], tenantId: number) =>
  axios.post(`${ENDPOINT}/create`, { offer, tenantId, issueList }); //OFFER_ADMIN

export const validatedOffer = (param: number, tenantId: number) =>
  axios.post(`${ENDPOINT}/validate-offer`, { param, tenantId }); //OFFER_ADMIN

export const getOfferById = (param: string | number, tenantId: number) =>
  axios.post<GenericResponse<Offer>>(`${ENDPOINT}/get-by-id`, {
    param,
    tenantId,
  }); //OFFER_GET

export const modifyOffer = (offer: OfferRequest, issueList: Issue[], tenantId: number) =>
  axios.put(`${ENDPOINT}/modify`, { offer, issueList, tenantId }); //OFFER_ADMIN

export const listOfferByProject = (param: number | string, tenantId: number) =>
  axios.post<GenericListResponse<Offer>>(`${ENDPOINT}/list-by-project`, {
    param,
    tenantId,
  }); //OFFER_GET

export const listOfferByTenant = (
  page: number,
  size: number,
  tenantId: number,
  search: string = "",
  sort: string = "",
) =>
  axios.get<GenericPageResponse<Offer>>(
    `${ENDPOINT}/all?page=${page}&size=${size}&tenantId=${tenantId}&search=${search}&sort=${sort}`,
  ); //OFFER_GET

export const acceptOffer = (param: number, tenantId: number) =>
  axios.post(`${ENDPOINT}/accept`, { param, tenantId }); //OFFER_GET

export const declineOffer = (param: number, tenantId: number) =>
  axios.post(`${ENDPOINT}/decline`, { param, tenantId }); //OFFER_GET

export const removeOfferIssue = (param: number, tenantId: number) =>
  axios.post(`${ENDPOINT}/remove-issue-from-offer`, { param, tenantId }); //OFFER_ADMIN

export const resetOfferStatus = (param: number, tenantId: number) =>
  axios.post(`${ENDPOINT}/status-update`, { offerId: param, status: "PENDING", tenantId }); //OFFER_GET

export const getOfferHistoryList = (
  page: number,
  size: number,
  tenantId: number | string,
  search: string = "",
  sort = "",
) =>
  axios.get<GenericPageResponse<OfferHistoryEntry>>(
    `${ENDPOINT}/all-history?page=${page}&size=${size}&tenantId=${tenantId}&search=${search}&sort=${sort}`,
  ); //OFFER_GET, ISSUE_GET

export const getOfferHistoryByHistoryId = (param: string | number, tenantId: number | string) =>
  axios.post<GenericResponse<OfferHistoryEntry>>(`${ENDPOINT}/get-history-by-id`, {
    param,
    tenantId,
  }); //OFFER_GET

export const exportOfferPdf = (
  offerId: string,
  tenantId: string,
  files?: {
    file?: File;
  },
) => {
  const formData = new FormData();
  formData.set("offerId", offerId);
  formData.set("tenantId", tenantId);
  files?.file && formData.set("file", files?.file);

  return axios.post(`${ENDPOINT}/export-offer-to-pdf`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}; //DELIVERY_ADMIN

export const sendOfferEmail = (params: {
  subject: string | null;
  customers: { email: string; name: string }[];
  offerId: number;
  tenantId: number;
  customMessage: string | null;
}) => {
  return axios.post(`${ENDPOINT}/send-offer`, params);
};
