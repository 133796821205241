import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { RootState } from "config/store";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { hasAuthority } from "shared/authorization";
import { removeItemCategory } from "shared/network/item.api";
import { Item } from "shared/types";
import { ItemFormValues } from "./ItemForm";

type Props = {
  item?: Item;
  deleteTarget?: any;
  setDeleteTarget?: any;
  selectedCategories: any;
  setSelectedCategories: any;
};

const DeleteCategoryDialog = ({
  item,
  deleteTarget,
  setDeleteTarget,
  selectedCategories,
  setSelectedCategories,
}: Props) => {
  const { t } = useTranslation();
  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const { tenant } = selectedRelTenant;
  const { setValue } = useFormContext<ItemFormValues>();

  const removeCategory = async () => {
    if (deleteTarget !== null) {
      selectedCategories.splice(selectedCategories.indexOf(deleteTarget), 1);
      setSelectedCategories(selectedCategories);
    }
    setDeleteTarget(null);
    if (item) {
      try {
        await removeItemCategory(
          Number.parseInt(deleteTarget?.id.toString() || ""),
          item.id.toString(),
          tenant.id,
        );
      } catch {}
    }
    setValue("categories", selectedCategories);
  };

  return (
    <Dialog open={!!deleteTarget} maxWidth="sm" fullWidth onClose={() => setDeleteTarget(null)}>
      <DialogTitle>{t("item.category.deleteCategoryTitle")}</DialogTitle>
      <DialogContent>
        <Box>
          <Typography>{t("item.category.deleteWarning")}</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box display="flex" gridGap={8}>
          <Button variant="text" onClick={() => setDeleteTarget(null)}>
            {t("common:button.cancel")}
          </Button>
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "COMPANY_ADMIN",
          ]) && (
            <Button onClick={removeCategory} variant="contained">
              {t("common:button.delete")}
            </Button>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteCategoryDialog;
