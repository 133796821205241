import {
  Box,
  Button,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Close, Info } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import { Autocomplete } from "@material-ui/lab";
import FormCard from "components/FormCard";
import { ISSUE_USER_TYPE } from "config/constants";
import { RootState } from "config/store";
import { useEffect, useState } from "react";
import {
  Controller,
  FieldArrayMethodProps,
  FieldArrayWithId,
  useFormContext,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { getUserById, listUsers } from "shared/network/user.api";
import { Issue, User } from "shared/types";
import { IssueFromValues } from "../IssueCreate";
import { useDebouncedCallback } from "use-debounce";
import {
  PAGEABLE_AUTOCOMPLETE_MIN_STRING_LENGTH,
  PAGEABLE_AUTOCOMPLETE_CALLBACK_DELAY,
} from "config/constants";
import { COLORS } from "config/theme";

const useStyles = makeStyles({
  empty: {
    fontSize: 20,
    width: "100%",
  },
});

type Props = {
  issue?: Issue;
  append: (
    value:
      | Partial<{
          user: User | null;
          userType: string | null;
        }>
      | Partial<{
          user: User | null;
          userType: string | null;
        }>[],
    options?: FieldArrayMethodProps | undefined,
  ) => void;
  fields: FieldArrayWithId<IssueFromValues, "users", "key">[];
  remove: (index?: number | number[] | undefined) => void;
  replace: (
    value:
      | Partial<{
          user: User | null;
          userType: string | null;
        }>
      | Partial<{
          user: User | null;
          userType: string | null;
        }>[],
  ) => void;
  isIssueTemplateChanged: boolean;
};

export async function getUser(tenantId: number, id?: string) {
  try {
    if (id) {
      const user = (await getUserById(tenantId, id)).data.user;
      return user;
    } else {
      throw new Error();
    }
  } catch {
    return null;
  }
}

const UserCard = ({ issue, append, fields, remove, replace, isIssueTemplateChanged }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { control, watch } = useFormContext<IssueFromValues>();
  const selectedIssueTemplate = watch("issueTemplate");

  const tenant = useSelector(
    (state: RootState) => state?.authentication?.selectedRelTenant?.tenant,
  );

  const [userSearch, setUserSearch] = useState<string>("");

  const userListQuery = useQuery(["issueUserList", tenant.id, userSearch], async () => {
    const { data } = await listUsers(0, 10, tenant.id, userSearch ? `name:${userSearch}` : ``);
    return data.page;
  });

  const handleUserSearchChange = useDebouncedCallback((value: string) => {
    if (value.length >= PAGEABLE_AUTOCOMPLETE_MIN_STRING_LENGTH || value.length === 0) {
      setUserSearch(value);
    }
  }, PAGEABLE_AUTOCOMPLETE_CALLBACK_DELAY);

  useEffect(() => {
    const userListData: {
      user: User | null;
      userType: string | null;
    }[] = [];
    issue?.users?.forEach(user => {
      userListData.push({ user: user.user, userType: user.userType });
    });
    if (isIssueTemplateChanged && selectedIssueTemplate) {
      selectedIssueTemplate.relIssueTemplateUsers?.forEach(user => {
        userListData.push({
          user: user.user,
          userType: user.userType || null,
        });
      });
    }
    replace(userListData);
  }, [issue, selectedIssueTemplate, replace]); //eslint-disable-line

  return (
    <FormCard title={t("issues.formValues.userCard.title")}>
      {fields.length ? (
        fields.map((field, index) => (
          <Grid key={field.key} container spacing={2} alignItems="center">
            <Grid item xs={6}>
              <Typography variant="body2" style={{ paddingLeft: 8 }}>
                {t("issues.formValues.userCard.user", {
                  index: index + 1,
                })}
              </Typography>
            </Grid>
            <Grid item xs={6} style={{ textAlign: "right" }}>
              <Tooltip title={t("common:button.delete").toString()}>
                <IconButton size="small" onClick={() => remove(index)}>
                  <Close fontSize="small" />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                control={control}
                name={`users.${index}.user`}
                defaultValue={issue?.users?.[index]?.user || null}
                rules={{ required: t("validation.required").toString() }}
                render={({ field, fieldState }) => (
                  <Autocomplete
                    {...field}
                    style={{ marginBottom: 8 }}
                    options={userListQuery?.data?.content || []}
                    onInputChange={(event, newInputValue) => {
                      handleUserSearchChange(newInputValue);
                    }}
                    getOptionLabel={(option: User) => {
                      if (option.name) {
                        return `${option.name}`;
                      }
                      return "";
                    }}
                    getOptionSelected={option => option.id === field.value?.id}
                    onChange={(_, value) => {
                      field.onChange(value);
                      handleUserSearchChange("");
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <Tooltip
                              style={{
                                paddingRight: "2px",
                              }}
                              title={t("tooltip.user").toString()}
                            >
                              <Info style={{ color: COLORS.lightBlue }} />
                            </Tooltip>
                          ),
                        }}
                        label={t("issues.formValues.userCard.userName")}
                        InputLabelProps={{
                          shrink: true,
                          required: true,
                        }}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                control={control}
                name={`users.${index}.userType`}
                defaultValue={issue?.users?.[index]?.userType || null}
                rules={{
                  required: t("validation.required").toString(),
                }}
                render={({ field, fieldState }) => (
                  <Autocomplete
                    {...field}
                    style={{ marginBottom: 8 }}
                    options={ISSUE_USER_TYPE || []}
                    getOptionLabel={(option: string) => {
                      if (option) {
                        return t(
                          `issues.formValues.userCard.userType.${option.toString()}`,
                        ).toString();
                      }
                      return "";
                    }}
                    getOptionSelected={option => option === field.value}
                    onChange={(_, value) => field.onChange(value)}
                    renderInput={params => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <Tooltip
                              style={{
                                paddingRight: "2px",
                              }}
                              title={t("tooltip.userType").toString()}
                            >
                              <Info style={{ color: COLORS.lightBlue }} />
                            </Tooltip>
                          ),
                        }}
                        label={t("issues.formValues.userCard.userType.title")}
                        InputLabelProps={{
                          shrink: true,
                          required: true,
                        }}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>
          </Grid>
        ))
      ) : (
        <Grid item container xs={12} justifyContent="center">
          <Box textAlign="center">
            <Typography className={classes.empty} color="secondary">
              {t("common:emptyUserList")}
            </Typography>
          </Box>
        </Grid>
      )}
      <Box display="flex" justifyContent="center" width="100%">
        <Button
          variant="outlined"
          color="primary"
          style={{ marginBottom: 8, marginTop: 16 }}
          onClick={event => {
            event.stopPropagation();
            append({});
          }}
          startIcon={<AddIcon />}
        >
          {t("issues.formValues.userCard.add")}
        </Button>
      </Box>
    </FormCard>
  );
};

export default UserCard;
