import { Grid, MenuItem, TextField, Tooltip, Typography } from "@material-ui/core";
import { CalendarToday, Info } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardDatePicker } from "@material-ui/pickers";
import FormCard from "components/FormCard";
import NumberField from "components/NumberField";
import {
  COMPLETITION_PERCENTAGE,
  NUMBER_FORMAT,
  PAGEABLE_AUTOCOMPLETE_CALLBACK_DELAY,
  PAGEABLE_AUTOCOMPLETE_MIN_STRING_LENGTH,
  PRIORITY,
  STATUSES,
} from "config/constants";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { addDays } from "date-fns";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { listCompanyByTenant } from "shared/network/company.api";
import { listContract } from "shared/network/contract.api";
import { getIssueTemplateById, listIssueTemplate } from "shared/network/issue-template.api";
import { listIssueTypeByTenant } from "shared/network/issue-type.api";
import { listOfferByTenant } from "shared/network/offer.api";
import { listProject } from "shared/network/project.api";
import { listUsers } from "shared/network/user.api";
import { listWorksheet } from "shared/network/worksheet.api";
import {
  Company,
  Contract,
  Issue,
  IssueTemplate,
  IssueType,
  Offer,
  Project,
  RelItemCompany,
  User,
  Worksheet,
} from "shared/types";
import { useDebouncedCallback } from "use-debounce";
import { IssueFromValues } from "../IssueCreate";
import CustomField from "./CustomField";

type Props = {
  issue?: Issue;
  worksheetSelected?: boolean;
  isWorksheet?: boolean;
  setWorksheetSelected: Dispatch<SetStateAction<boolean>>;
  setIsIssueTemplateChanged: Dispatch<SetStateAction<boolean>>;
  removeUser: (index: number) => void;
};

const DataCard = ({
  issue,
  isWorksheet,
  setIsIssueTemplateChanged,
  removeUser,
  setWorksheetSelected,
  worksheetSelected,
}: Props) => {
  const { t } = useTranslation();
  const { control, setValue, watch, getValues } = useFormContext<IssueFromValues>();
  const selectedCompanyId = watch("company")?.id;
  const selectedIssueTemplate = watch("issueTemplate");
  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const tenant = selectedRelTenant.tenant;
  const location = useLocation();
  const relItemCompany = location?.state as RelItemCompany;

  const isPartner =
    account.user.relUserCompany?.length !== 0 &&
    !account.user.isSuperAdmin &&
    !selectedRelTenant.isTenantAdmin;

  const [userSearch, setUserSearch] = useState<string>("");
  const [assigneeSearch, setAssigneeSearch] = useState<string>("");
  const [companySearch, setCompanySearch] = useState<string>("");
  const [contractorCompanySearch, setContractorCompanySearch] = useState<string>("");
  const [projectSearch, setProjectSearch] = useState<string>("");
  const [contractSearch, setContractSearch] = useState<string>("");
  const [offerSearch, setOfferSearch] = useState<string>("");
  const [issueTemplateSearch, setIssueTemplateSearch] = useState<string>("");

  const offerListForOrderQuery = useQuery(
    ["offerListForOrderQuery", tenant.id, selectedCompanyId, offerSearch],
    async () => {
      const { data } = await listOfferByTenant(
        0,
        10,
        tenant.id,
        (offerSearch ? `(name:${offerSearch};(OR)identifier:${offerSearch};);` : ``) +
          (selectedCompanyId ? `company.id:${selectedCompanyId}` : ``),
      );
      return data.page.content;
    },
  );

  const contractListForOrderQuery = useQuery(
    ["contractListForOrderQuery", tenant.id, selectedCompanyId, contractSearch],
    async () => {
      const { data } = await listContract(
        0,
        10,
        tenant.id,
        (contractSearch ? `(name:${contractSearch};(OR)identifier:${contractSearch};);` : ``) +
          (selectedCompanyId ? `company.id:${selectedCompanyId}` : ``),
      );

      return data.page.content;
    },
    { enabled: !isPartner },
  );

  const userListQuery = useQuery(["issueUserListQuery", tenant.id, userSearch], async () => {
    const { data } = await listUsers(
      0,
      10,
      tenant.id,
      account.user.isSuperAdmin && userSearch
        ? `name:${userSearch};`
        : userSearch
        ? `name:${userSearch};isSuperAdmin:false;`
        : "",
    );

    return data.page?.content;
  });

  const userListForAssigneeQuery = useQuery(
    ["userListForAssigneeQuery", tenant.id, assigneeSearch],
    async () => {
      const { data } = await listUsers(
        0,
        10,
        tenant.id,
        account.user.isSuperAdmin && assigneeSearch
          ? `name:${assigneeSearch};`
          : assigneeSearch
          ? `name:${assigneeSearch};isSuperAdmin:false;`
          : "",
      );

      return data.page?.content;
    },
  );

  const companyListQuery = useQuery(
    ["issueCompanyListQuery", tenant.id, companySearch],
    async () => {
      const { data } = await listCompanyByTenant(
        0,
        10,
        tenant.id,
        companySearch ? `name:${companySearch}` : ``,
      );
      return data;
    },
  );
  const contractorCompanyListQuery = useQuery(
    ["issueContractorCompanyListQuery", tenant.id, contractorCompanySearch],
    async () => {
      const { data } = await listCompanyByTenant(
        0,
        10,
        tenant.id,
        contractorCompanySearch ? `name:${contractorCompanySearch}` : ``,
      );
      return data;
    },
  );

  const projectListQuery = useQuery(
    ["projectList", tenant.id, projectSearch, selectedCompanyId],
    async () => {
      const { data } = await listProject(
        0,
        10,
        tenant.id,
        (projectSearch ? `(name:${projectSearch};(OR)identifier:${projectSearch};)` : ``) +
          (selectedCompanyId ? `company.id:${selectedCompanyId}` : ``),
      );
      return data.page?.content;
    },
    { enabled: !!selectedCompanyId },
  );

  const issueTypeListQuery = useQuery(["issueIssueTypeListQuery", tenant.id], async () => {
    const { data } = await listIssueTypeByTenant(tenant.id);
    return data.items;
  });

  const issueTemplateList = useQuery(
    ["issueIssueTemplateQuery", tenant.id, issueTemplateSearch],
    async () => {
      const { data } = await listIssueTemplate(
        0,
        10,
        tenant.id,
        issueTemplateSearch ? `name:${issueTemplateSearch}` : ``,
      );

      return data.page?.content;
    },
  );

  useQuery(
    ["getIssueTemplateByIdQueryForIssue", issue?.issueTemplateId],
    async () => {
      if (issue?.issueTemplateId) {
        const { data } = await getIssueTemplateById(
          Number.parseInt(issue?.issueTemplateId.toString()),
          tenant.id,
        );
        return data.item;
      }
    },
    {
      enabled: !!issue?.issueTemplateId,
      onSuccess: data => data && setValue("issueTemplate", data),
    },
  );

  const worksheetListQuery = useQuery(["worksheetListForIssue", tenant.id], async () => {
    const { data } = await listWorksheet(tenant.id);
    return data.items;
  });

  const handleSearchStringChange = useDebouncedCallback((value: string, type: string) => {
    if (value.length >= PAGEABLE_AUTOCOMPLETE_MIN_STRING_LENGTH || value.length === 0) {
      switch (type) {
        case "USER":
          setUserSearch(value);
          break;
        case "ASSIGNEE":
          setAssigneeSearch(value);
          break;
        case "COMPANY":
          setCompanySearch(value);
          break;
        case "CONTRACTOR_COMPANY":
          setContractorCompanySearch(value);
          break;
        case "PROJECT":
          setProjectSearch(value);
          break;
        case "CONTRACT":
          setContractSearch(value);
          break;
        case "OFFER":
          setOfferSearch(value);
          break;
        case "ISSUE_TEMPLATE":
          setIssueTemplateSearch(value);
          break;
      }
    }
  }, PAGEABLE_AUTOCOMPLETE_CALLBACK_DELAY);

  useEffect(() => {
    if (issue?.relIssueCustomFields) {
      issue?.relIssueCustomFields?.forEach(relCustomField => {
        setValue(`custom.${relCustomField.customField.id}`, relCustomField.value);
      });
    }
  }, [issue, setValue]);

  useEffect(() => {
    setValue(`company`, relItemCompany?.companySite?.company);
  }, [relItemCompany?.item]); //eslint-disable-line

  return (
    <FormCard title={t("issues.details")}>
      <Grid container spacing={2} style={{ paddingBottom: 40 }}>
        <Grid item xs={12} sm={!!issue?.id ? 5 : 6}>
          <Controller
            control={control}
            name="issueTemplate"
            defaultValue={null}
            render={({ field, fieldState }) => (
              <Autocomplete
                {...field}
                disabled={!!issue}
                onChange={(_, value) => {
                  field.onChange(value);
                  handleSearchStringChange("", "ISSUE_TEMPLATE");
                  setIsIssueTemplateChanged(true);
                  if (!getValues("name")) {
                    setValue("name", value?.name || "");
                  }
                  if (!getValues("assignedUser")) {
                    setValue("assignedUser", value?.assignedUser || null);
                  }
                  if (!getValues("priority")) {
                    setValue("priority", value?.priority || "");
                  }

                  setValue("worksheet", value?.worksheet || null);

                  if (value?.worksheet) {
                    setWorksheetSelected(true);
                  } else {
                    setWorksheetSelected(false);
                  }

                  if (!getValues("company")) {
                    setValue("company", value?.company || null);
                  }
                  if (!getValues("project")) {
                    setValue("project", value?.project || null);
                  }
                  if (!getValues("description")) {
                    setValue("description", value?.description || "");
                  }
                  if (!getValues("offer")) {
                    setValue("offer", value?.offer || null);
                  }
                  if (!getValues("contract")) {
                    setValue("contract", value?.contract || "");
                  }
                  if (!getValues("estimatedTime")) {
                    setValue(
                      "estimatedTime",
                      value?.estimatedTime ? Number.parseFloat(value.estimatedTime.toString()) : 0,
                    );
                  }
                  if (!getValues("endDate")) {
                    setValue(
                      "endDate",
                      value?.deadlineDays
                        ? addDays(new Date(), parseInt(value.deadlineDays.toString()))
                        : new Date(),
                    );
                  }
                  if (!getValues("issueType")) {
                    setValue(
                      "issueType",
                      issueTypeListQuery.data?.find(type => type.id === value?.issueTypeId) || null,
                    );
                  }
                }}
                onInputChange={(event, newInputValue) => {
                  handleSearchStringChange(newInputValue, "ISSUE_TEMPLATE");
                }}
                options={issueTemplateList.data || []}
                getOptionLabel={(option: IssueTemplate) => option.name}
                getOptionSelected={option => option.id === field.value?.id}
                renderInput={params => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <Tooltip
                          style={{
                            paddingRight: "2px",
                          }}
                          title={t("tooltip.issueTemplate").toString()}
                        >
                          <Info style={{ color: COLORS.lightBlue }} />
                        </Tooltip>
                      ),
                    }}
                    label={"Feladatsablon"}
                    InputLabelProps={{ shrink: true }}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={!!issue?.id ? 5 : 6}>
          <Controller
            control={control}
            name="issueType"
            defaultValue={issue?.issueType || null}
            rules={{ required: t("validation.required").toString() }}
            render={({ field, fieldState }) => (
              <Autocomplete
                {...field}
                onChange={(_, value) => field.onChange(value)}
                options={issueTypeListQuery.data || []}
                getOptionLabel={(option: IssueType) => option.type}
                getOptionSelected={option => option.id === field.value?.id}
                renderInput={params => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <Tooltip
                          style={{
                            paddingRight: "2px",
                          }}
                          title={t("tooltip.issueType").toString()}
                        >
                          <Info style={{ color: COLORS.lightBlue }} />
                        </Tooltip>
                      ),
                    }}
                    label={t("issues.formValues.issueType")}
                    InputLabelProps={{ shrink: true, required: true }}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            )}
          />
        </Grid>
        {!!issue?.id && (
          <Grid
            item
            xs={12}
            sm={2}
            style={{
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography style={{ textAlign: "center" }}>{`#${issue?.id}`}</Typography>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Controller
            control={control}
            name="name"
            defaultValue={issue?.name || ""}
            rules={{ required: t("validation.required").toString() }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label={t("issues.formValues.subject")}
                InputLabelProps={{ shrink: true, required: true }}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          style={
            isPartner ? { width: 0, height: 0, overflow: "hidden", display: "none" } : undefined
          }
        >
          <Controller
            control={control}
            name="worksheet"
            defaultValue={issue?.worksheet || null}
            render={({ field, fieldState }) => (
              <Autocomplete
                {...field}
                disabled={issue?.worksheet || worksheetSelected}
                onChange={(_, value) => {
                  field.onChange(value);
                }}
                options={worksheetListQuery.data || []}
                getOptionLabel={(option: Worksheet) => option.name || ""}
                getOptionSelected={option => option.id === field.value?.id}
                renderInput={params => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                    }}
                    label={t("issues.formValues.worksheet")}
                    InputLabelProps={{ shrink: true }}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item sm={12} md={4}>
          <Controller
            control={control}
            name="assignedUser"
            defaultValue={issue?.assignedUser || null}
            render={({ field, fieldState }) => (
              <Autocomplete
                {...field}
                onChange={(_, value) => {
                  const row = getValues("users")?.findIndex(
                    user =>
                      user.user?.id === getValues("assignedUser")?.id &&
                      user?.userType === "ASSIGNEE",
                  );
                  if (row > -1) {
                    removeUser(row);
                  }
                  field.onChange(value);
                  handleSearchStringChange("", "ASSIGNEE");
                }}
                onInputChange={(_, newInputValue) => {
                  handleSearchStringChange(newInputValue, "ASSIGNEE");
                }}
                options={userListForAssigneeQuery.data || []}
                getOptionLabel={(option: User) => option.name}
                getOptionSelected={option => option.id === field.value?.id}
                renderInput={params => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <Tooltip
                          style={{
                            paddingRight: "2px",
                          }}
                          title={t("tooltip.assigned").toString()}
                        >
                          <Info style={{ color: COLORS.lightBlue }} />
                        </Tooltip>
                      ),
                    }}
                    label={t("issues.formValues.assignedUser")}
                    InputLabelProps={{ shrink: true }}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item sm={12} md={4}>
          <Controller
            control={control}
            name="responsibleUser"
            defaultValue={issue?.responsibleUser || null}
            render={({ field, fieldState }) => (
              <Autocomplete
                {...field}
                onChange={(_, value) => {
                  const row = getValues("users")?.findIndex(
                    user =>
                      user.user?.id === getValues("responsibleUser")?.id &&
                      user?.userType === "RESPONSIBLE",
                  );
                  if (row > -1) {
                    removeUser(row);
                  }

                  field.onChange(value);
                  handleSearchStringChange("", "USER");
                }}
                onInputChange={(event, newInputValue) => {
                  handleSearchStringChange(newInputValue, "USER");
                }}
                options={userListQuery.data || []}
                getOptionLabel={(option: User) => option.name}
                getOptionSelected={option => option.id === field.value?.id}
                renderInput={params => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <Tooltip
                          style={{
                            paddingRight: "2px",
                          }}
                          title={t("tooltip.relAssigned").toString()}
                        >
                          <Info style={{ color: COLORS.lightBlue }} />
                        </Tooltip>
                      ),
                    }}
                    label={t("issues.formValues.responsibleUser")}
                    InputLabelProps={{ shrink: true }}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item sm={12} md={4}>
          <Controller
            control={control}
            name="priority"
            defaultValue={issue?.priority || "NORMAL"}
            rules={{ required: t("validation.required").toString() }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label={t("issues.formValues.priority")}
                InputLabelProps={{ shrink: true, required: true }}
                SelectProps={{ displayEmpty: true }}
                select
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              >
                <MenuItem disabled value="">
                  <>{t("common:choose")}</>
                </MenuItem>
                {PRIORITY.map(priority => (
                  <MenuItem key={priority} value={priority}>
                    <>{t(`common:priority.${priority}`)}</>
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>
        <Grid item sm={12} md={4}>
          <Controller
            control={control}
            name="status"
            defaultValue={issue?.status || "NEW"}
            rules={{ required: t("validation.required").toString() }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label={t("issues.formValues.status")}
                InputLabelProps={{ shrink: true, required: true }}
                SelectProps={{ displayEmpty: true }}
                select
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              >
                <MenuItem disabled value="">
                  <>{t("common:choose")}</>
                </MenuItem>
                {STATUSES.map(status => (
                  <MenuItem key={status} value={status}>
                    <>{t(`common:statuses.${status}`)}</>
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>
        <Grid
          item
          sm={12}
          md={4}
          style={
            isPartner ? { width: 0, height: 0, overflow: "hidden", display: "none" } : undefined
          }
        >
          <Controller
            control={control}
            name="startDate"
            defaultValue={issue?.startDate ? new Date(issue?.startDate) : new Date()}
            rules={{ required: t("validation.required").toString() }}
            render={({ field, fieldState }) => (
              <KeyboardDatePicker
                {...field}
                ref={undefined}
                className="startDate"
                variant="inline"
                label={t("issues.formValues.startDate")}
                format="yyyy.MM.dd"
                InputLabelProps={{ shrink: true, required: true }}
                InputProps={{ endAdornment: <CalendarToday /> }}
                autoOk
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Grid>
        <Grid item sm={12} md={4}>
          <Controller
            control={control}
            name="endDate"
            defaultValue={issue?.endDate ? new Date(issue?.endDate) : addDays(new Date(), 5)}
            rules={{ required: t("validation.required").toString() }}
            render={({ field, fieldState }) => (
              <KeyboardDatePicker
                {...field}
                ref={undefined}
                variant="inline"
                className="endDate"
                label={t("issues.formValues.endDate")}
                format="yyyy.MM.dd"
                InputLabelProps={{ shrink: true, required: true }}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                InputProps={{ endAdornment: <CalendarToday /> }}
                autoOk
              />
            )}
          />
        </Grid>
        <Grid item sm={12} md={4}>
          <Controller
            control={control}
            name="completionPercentage"
            defaultValue={issue?.completionPercentage || 0}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label={t("issues.formValues.completionPercentage")}
                SelectProps={{ displayEmpty: true }}
                select
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              >
                <MenuItem value={0}>
                  <>{t("common:choose")}</>
                </MenuItem>
                {COMPLETITION_PERCENTAGE.map(percent => (
                  <MenuItem key={percent} value={percent}>
                    <>{t(`common:completionPercentage.${percent}`)}</>
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>
        <Grid
          item
          sm={12}
          md={4}
          style={
            isPartner ? { width: 0, height: 0, overflow: "hidden", display: "none" } : undefined
          }
        >
          <Controller
            control={control}
            name="estimatedTime"
            defaultValue={issue?.estimatedTime || 0}
            rules={{
              required: t("validation.required").toString(),
              validate: value => {
                if (value && !value.toString().match(NUMBER_FORMAT)) {
                  return t("common:validation.numberFormat").toString();
                }
              },
            }}
            render={({ field, fieldState }) => (
              <NumberField
                field={field}
                fieldState={fieldState}
                label={t("issues.formValues.estimatedTime")}
                InputLabelProps={{ shrink: true, required: true }}
                numberLimits={{ step: "0.01", min: "0" }}
              />
            )}
          />
        </Grid>
        <Grid
          item
          sm={12}
          md={4}
          style={
            isPartner ? { width: 0, height: 0, overflow: "hidden", display: "none" } : undefined
          }
        >
          <Controller
            control={control}
            name="parentIssueId"
            defaultValue={issue?.parentIssueId || ""}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label={t("issues.formValues.parentIssue")}
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </Grid>
        {!isWorksheet && (
          <Grid item sm={12} md={4}>
            <Controller
              control={control}
              name="company"
              defaultValue={issue?.company || account.user.relUserCompany?.[0]?.company || null}
              render={({ field, fieldState }) => (
                <Autocomplete
                  {...field}
                  disabled={isPartner}
                  onChange={(_, value) => {
                    field.onChange(value);
                    setValue("project", null);
                    handleSearchStringChange("", "COMPANY");
                  }}
                  onInputChange={(event, newInputValue) => {
                    handleSearchStringChange(newInputValue, "COMPANY");
                  }}
                  options={companyListQuery.data?.page?.content || []}
                  getOptionLabel={(option: Company) => option.name}
                  getOptionSelected={option => option.id === field.value?.id}
                  renderInput={params => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <Tooltip
                            style={{
                              paddingRight: "2px",
                            }}
                            title={t("tooltip.customer").toString()}
                          >
                            <Info style={{ color: COLORS.lightBlue }} />
                          </Tooltip>
                        ),
                      }}
                      label={t("issues.formValues.company")}
                      InputLabelProps={{ shrink: true }}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
        )}
        <Grid
          item
          sm={12}
          md={4}
          style={
            isPartner ? { width: 0, height: 0, overflow: "hidden", display: "none" } : undefined
          }
        >
          <Controller
            control={control}
            name="project"
            defaultValue={issue?.project || null}
            render={({ field, fieldState }) => (
              <Autocomplete
                {...field}
                disabled={!selectedCompanyId}
                onChange={(_, value) => {
                  field.onChange(value);
                  handleSearchStringChange("", "PROJECT");
                }}
                onInputChange={(event, newInputValue) => {
                  handleSearchStringChange(newInputValue, "PROJECT");
                }}
                options={projectListQuery.data || []}
                getOptionLabel={(option: Project) =>
                  (option.name || "") + (option.identifier ? ` (${option.identifier})` : "")
                }
                getOptionSelected={option => option.id === field.value?.id}
                renderInput={params => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <Tooltip
                          style={{
                            paddingRight: "2px",
                          }}
                          title={t("tooltip.project").toString()}
                        >
                          <Info style={{ color: COLORS.lightBlue }} />
                        </Tooltip>
                      ),
                    }}
                    label={t("issues.formValues.project")}
                    InputLabelProps={{ shrink: true }}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          style={
            isPartner ? { width: 0, height: 0, overflow: "hidden", display: "none" } : undefined
          }
        >
          <Controller
            control={control}
            name="offer"
            defaultValue={issue?.offer || null}
            render={({ field, fieldState }) => (
              <Autocomplete
                {...field}
                onChange={(_, value) => {
                  field.onChange(value);
                  handleSearchStringChange("", "OFFER");
                }}
                onInputChange={(event, newInputValue) => {
                  handleSearchStringChange(newInputValue, "OFFER");
                }}
                options={offerListForOrderQuery.data || []}
                disabled={!selectedCompanyId}
                getOptionLabel={(option: Offer) =>
                  option.identifier + (option.name ? ` (${option.name})` : "") || ""
                }
                getOptionSelected={option => option.id === field.value?.id}
                renderInput={params => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <Tooltip
                          style={{
                            paddingRight: "2px",
                          }}
                          title={t("tooltip.offer").toString()}
                        >
                          <Info style={{ color: COLORS.lightBlue }} />
                        </Tooltip>
                      ),
                    }}
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ displayEmpty: true }}
                    label={t("order.formValues.offer")}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          style={
            isPartner ? { width: 0, height: 0, overflow: "hidden", display: "none" } : undefined
          }
        >
          <Controller
            control={control}
            name="contract"
            defaultValue={issue?.contract || null}
            render={({ field, fieldState }) => (
              <Autocomplete
                {...field}
                onChange={(_, value) => {
                  field.onChange(value);
                  handleSearchStringChange("", "CONTRACT");
                }}
                onInputChange={(event, newInputValue) => {
                  handleSearchStringChange(newInputValue, "CONTRACT");
                }}
                options={contractListForOrderQuery.data || []}
                disabled={!selectedCompanyId}
                getOptionLabel={(option: Contract) => {
                  if (option.name) {
                    return option.name + (option.identifier ? ` (${option.identifier})` : "");
                  } else {
                    return option.identifier;
                  }
                }}
                getOptionSelected={option => option.id === field.value?.id}
                renderInput={params => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <Tooltip
                          style={{
                            paddingRight: "2px",
                          }}
                          title={t("tooltip.contract").toString()}
                        >
                          <Info style={{ color: COLORS.lightBlue }} />
                        </Tooltip>
                      ),
                    }}
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ displayEmpty: true }}
                    label={t("order.formValues.contract")}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid
          item
          sm={12}
          md={4}
          style={
            isPartner ? { width: 0, height: 0, overflow: "hidden", display: "none" } : undefined
          }
        >
          <Controller
            control={control}
            name="contractorCompany"
            defaultValue={issue?.contractorCompany || null}
            render={({ field, fieldState }) => (
              <Autocomplete
                {...field}
                onChange={(_, value) => {
                  field.onChange(value);
                  handleSearchStringChange("", "CONTRACTOR_COMPANY");
                }}
                onInputChange={(event, newInputValue) => {
                  handleSearchStringChange(newInputValue, "CONTRACTOR_COMPANY");
                }}
                options={contractorCompanyListQuery.data?.page?.content || []}
                getOptionLabel={(option: Company) => option.name}
                getOptionSelected={option => option.id === field.value?.id}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={t("issues.formValues.contractorCompany")}
                    InputLabelProps={{ shrink: true }}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            )}
          />
        </Grid>

        {!isWorksheet && (
          <Grid item xs={12}>
            <Controller
              control={control}
              name="description"
              defaultValue={issue?.description || ""}
              rules={{ required: t("validation.required").toString() }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  multiline
                  minRows={4}
                  label={t("issues.formValues.description")}
                  InputLabelProps={{ shrink: true, required: true }}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
        )}
        {!isWorksheet &&
          !!!issue &&
          selectedIssueTemplate?.relIssueTemplateCustomFields?.map((relCustomField, index) => {
            const htmlAttributes = JSON.parse(relCustomField.customField.htmlAttributes);
            return (
              <CustomField
                name={relCustomField.customField.id}
                defaultValue={relCustomField.defaultValue}
                htmlAttributes={htmlAttributes}
                type={relCustomField.customField.type}
                index={index}
                label={relCustomField.customField.name}
                fieldValues={relCustomField?.customField?.fieldValues}
              />
            );
          })}
        {!isWorksheet &&
          !!issue &&
          issue.relIssueCustomFields?.map((relCustomField, index) => {
            const htmlAttributes = JSON.parse(relCustomField.customField.htmlAttributes);
            return (
              <CustomField
                name={relCustomField.customField.id}
                defaultValue={relCustomField.defaultValue}
                htmlAttributes={htmlAttributes}
                type={relCustomField.customField.type}
                index={index}
                label={relCustomField.customField.name}
                fieldValues={relCustomField?.customField?.fieldValues}
              />
            );
          })}
      </Grid>
    </FormCard>
  );
};

export default DataCard;
