import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import SignatureCanvas from "components/SignatureCanvas";
import { useEffect, useRef, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Issue } from "shared/types";
import { IssueFromValues } from "./IssueCreate";
import ContactCard from "./components/ContactCard";
import CostCard from "./components/CostCard";
import DataCard from "./components/DataCard";
import ItemCard from "./components/ItemCard";
import StatementCard from "./components/StatementCard";
import ToolCard from "./components/ToolCard";
import UserCard from "./components/UserCard";

type Props = {
  issue?: Issue;
  isLoading?: boolean;
};

const IssueForm = ({ isLoading, issue }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { control, watch } = useFormContext<IssueFromValues>();
  const [worksheetSelected, setWorksheetSelected] = useState(false);
  const {
    append: appendItem,
    fields: fieldsItem,
    remove: removeItem,
    replace: replaceItem,
  } = useFieldArray({ control, name: "items", keyName: "key" });
  const isCompleted = watch("status") === "COMPLETED";

  const {
    append: appendTool,
    fields: fieldsTool,
    remove: removeTool,
    replace: replaceTool,
  } = useFieldArray({
    control,
    name: "tools",
    keyName: "key",
  });

  const {
    append: appendUser,
    fields: fieldsUser,
    remove: removeUser,
    replace: replaceUser,
  } = useFieldArray({
    control,
    name: "users",
    keyName: "key",
  });

  const [isWorksheet, setIsWorksheet] = useState(false);
  const issueTemplate = watch("issueTemplate");
  const [isIssueTemplateChanged, setIsIssueTemplateChanged] = useState(false);

  const ref = useRef<HTMLDivElement | null>();
  const [open, setOpen] = useState(false);
  const [offset, setOffset] = useState<{ top: number; left: number }>({ top: 0, left: 0 });

  useEffect(() => {
    setIsWorksheet(issueTemplate?.id === 17);
  }, [issueTemplate]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Grid container justifyContent="center" spacing={2}>
        <Grid container item xs={12}>
          <DataCard
            worksheetSelected={worksheetSelected}
            setWorksheetSelected={setWorksheetSelected}
            issue={issue}
            isWorksheet={isWorksheet}
            setIsIssueTemplateChanged={setIsIssueTemplateChanged}
            removeUser={removeUser}
          />
        </Grid>
        {isWorksheet && (
          <Grid container item xs={12}>
            <ContactCard issue={issue} />
          </Grid>
        )}
        {isWorksheet && (
          <>
            <Grid item container xs={12}>
              <ToolCard
                issue={issue}
                append={appendTool}
                fields={fieldsTool}
                remove={removeTool}
                replace={replaceTool}
                isIssueTemplateChanged={isIssueTemplateChanged}
              />
            </Grid>
            <Grid item container xs={12}>
              <ItemCard
                issue={issue}
                append={appendItem}
                fields={fieldsItem}
                remove={removeItem}
                replace={replaceItem}
                isIssueTemplateChanged={isIssueTemplateChanged}
              />
            </Grid>
            <Grid item container xs={12}>
              <UserCard
                issue={issue}
                append={appendUser}
                fields={fieldsUser}
                remove={removeUser}
                replace={replaceUser}
                isIssueTemplateChanged={isIssueTemplateChanged}
              />
            </Grid>
          </>
        )}
        {isWorksheet && (
          <Grid container item xs={12}>
            <CostCard issue={issue} />
          </Grid>
        )}
        {isWorksheet && (
          <Grid container item xs={12}>
            <StatementCard issue={issue} />
          </Grid>
        )}
        {!isWorksheet && (
          <>
            <Grid item container xs={12}>
              <ItemCard
                issue={issue}
                append={appendItem}
                fields={fieldsItem}
                remove={removeItem}
                replace={replaceItem}
                isIssueTemplateChanged={isIssueTemplateChanged}
              />
            </Grid>
            <Grid item container xs={12} md={6}>
              <ToolCard
                issue={issue}
                append={appendTool}
                fields={fieldsTool}
                remove={removeTool}
                replace={replaceTool}
                isIssueTemplateChanged={isIssueTemplateChanged}
              />
            </Grid>
            <Grid item container xs={12} md={6}>
              <UserCard
                issue={issue}
                append={appendUser}
                fields={fieldsUser}
                remove={removeUser}
                replace={replaceUser}
                isIssueTemplateChanged={isIssueTemplateChanged}
              />
            </Grid>
          </>
        )}
      </Grid>
      {isCompleted && (
        <Dialog open={open} onClose={handleClose} disablePortal>
          <DialogTitle>{t("invoice.modifyStatus")}</DialogTitle>
          <DialogContent
            ref={ref}
            onScroll={() => {
              setOffset({
                top: ref.current?.scrollTop || 0,
                left: ref.current?.scrollLeft || 0,
              });
            }}
          >
            <Grid item xs={12} container justifyContent="center">
              <SignatureCanvas id={"signature"} offsetTop={offset.top} offsetLeft={offset.left} />
            </Grid>
          </DialogContent>
          <DialogActions>
            <Box display="flex" justifyContent="center" gridGap={8}>
              <Button color="primary" variant="text" onClick={handleClose}>
                {t("common:button.cancel")}
              </Button>
              <Button type="submit" disabled={isLoading} color="primary">
                {t("common:button.save")}
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      )}
      <Box display="flex" justifyContent="center" gridGap={8} p={2} width="100%">
        <Button color="primary" variant="text" onClick={history.goBack}>
          {t("common:button.cancel")}
        </Button>
        <Button
          type={isCompleted ? "button" : "submit"}
          color="primary"
          disabled={isLoading}
          onClick={handleOpen}
        >
          {issue ? t("common:button.save") : t("common:button.create")}
        </Button>
      </Box>
    </>
  );
};

export default IssueForm;
