import { faWandMagicSparkles } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { AddBox, Check, Delete, Edit, Image, Settings } from "@material-ui/icons";
import DetailsCard from "components/DetailsCard";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import { deleteApiKey, generateApiKey, getApiKey, modifyTenant } from "shared/network/tenant.api";
import { fetchAccount } from "shared/reducers/authentication";
import displayAddress from "shared/util/displayAddress";
import BankAccountList from "views/Company/CompanyDetailsTabs/BankAccount/BankAccountList";
import OwnCompanyAddTechUser from "views/OwnCompany/OwnCompanyAddTechUser";
import ContractType from "./ContractType";
import CompanyLogoDialog from "./TenantIcon/CompanyLogoDialog";
import TenantSites from "./TenantSites";

const TenantProfileDetails = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const tenant = selectedRelTenant.tenant;

  const [open, setOpen] = useState(false);
  const [isCompanyLogoOpen, setIsCompanyLogoOpen] = useState(false);

  const [apiKeyLoading, setApiKeyLoading] = useState(false);
  const [apiKeyDeleteLoading, setApiKeyDeleteLoading] = useState(false);

  async function apiKeyGenerate() {
    setApiKeyLoading(true);
    try {
      await generateApiKey(tenant?.id);
      enqueueSnackbar("Sikeres API kulcs generálás", { variant: "success" });
      getApiKeyQuery.refetch();
    } catch {
      enqueueSnackbar("Sikertelen API kulcs generálás", { variant: "error" });
    }
    setApiKeyLoading(false);
  }

  async function apiKeyDelete() {
    setApiKeyDeleteLoading(true);
    try {
      await deleteApiKey(tenant?.id);
      enqueueSnackbar("Sikeres API kulcs törlés", { variant: "success" });
      getApiKeyQuery.refetch();
    } catch {
      enqueueSnackbar("Sikertelen API kulcs törlés", { variant: "error" });
    }
    setApiKeyDeleteLoading(false);
  }

  const isPartner =
    account.user.relUserCompany?.length !== 0 &&
    !account.user.isSuperAdmin &&
    !selectedRelTenant.isTenantAdmin;

  const getApiKeyQuery = useQuery(
    ["getApiKeyQuery"],
    async () => {
      const { data } = await getApiKey(tenant?.id);
      return data;
    },
    { enabled: !isPartner },
  );

  const [timebeezApiKey, setTimebeezApiKey] = useState("");

  const onSubmit = async () => {
    try {
      if (timebeezApiKey) {
        await modifyTenant({ ...tenant, timebeezApiKey });
        dispatch(fetchAccount());
        enqueueSnackbar(
          t("common:notification.modify.success", {
            subject: t("tenant.subject"),
          }),
          {
            variant: "success",
          },
        );
      }
    } catch {
      enqueueSnackbar(
        t("common:notification.modify.failure", {
          subject: t("tenant.subject"),
        }),
        {
          variant: "error",
        },
      );
    }
  };

  useEffect(() => {
    setTimebeezApiKey(tenant?.timebeezApiKey || "");
  }, [tenant?.timebeezApiKey]);

  return (
    <Box display="flex" flexDirection="column" gridGap={16} pb={2}>
      <DetailsCard
        title={t("tenant.datas")}
        headerButtons={
          <>
            {(account.user.isSuperAdmin || selectedRelTenant.isTenantAdmin) && (
              <Box display="flex" gridGap={8}>
                <Button
                  size="small"
                  color="primary"
                  variant="outlined"
                  onClick={() => setIsCompanyLogoOpen(true)}
                  startIcon={<Image />}
                >
                  {t("tenant.addInvoiceIcon")}
                </Button>
                <CompanyLogoDialog
                  setOpen={setIsCompanyLogoOpen}
                  open={isCompanyLogoOpen}
                  tenantId={tenant.id}
                />
                <Button
                  size="small"
                  color="primary"
                  variant="outlined"
                  component={Link}
                  to={`/admin/tenants/tenant-modify?id=${tenant.id}`}
                  startIcon={<Edit />}
                >
                  {t("common:button.modify")}
                </Button>
              </Box>
            )}
          </>
        }
        columns={2}
        dataList={[
          {
            name: t("tenant.formValues.name"),
            value: tenant?.name || "-",
          },
          {
            name: t("tenant.formValues.tenantCode"),
            value: tenant?.tenantCode || "-",
          },
          {
            name: t("tenant.formValues.bankAccount"),
            value: tenant?.bankAccount || "-",
          },
          {
            name: t("tenant.formValues.taxNumber"),
            value: tenant?.taxNumber || "-",
          },
          {
            name: t("tenant.formValues.registrationNumber"),
            value: tenant?.registrationNumber || "-",
          },
          {
            name: t("tenant.formValues.baseLanguage"),
            value: tenant?.baseLanguage ? t(`common:language.${tenant.baseLanguage}`) : "-",
          },
          {
            name: t("tenant.formValues.baseCurrency.title"),
            value: tenant?.baseCurrency.name || "-",
          },
          {
            name: t("tenant.formValues.baseCurrencySource"),
            value: tenant?.baseCurrencySource || "-",
          },
          {
            name: t("tenant.formValues.description"),
            value: tenant?.description || "-",
          },
        ]}
      />
      <DetailsCard
        title={t("tenant.availability")}
        columns={2}
        dataList={[
          {
            name: t("tenant.formValues.phone"),
            value: tenant?.phone || "-",
          },
          {
            name: t("tenant.formValues.fullAddress"),
            value: tenant?.address ? displayAddress(tenant?.address) : "-",
          },
          {
            name: t("tenant.formValues.email"),
            value: tenant?.notificationEmail || "-",
          },
        ]}
      />
      {!isPartner && (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Card>
              <CardHeader
                title={
                  <Box display="flex" justifyContent="space-between">
                    {t("ownCompany.navTechnicalUser")}
                    {(account.user.isSuperAdmin || selectedRelTenant.isTenantAdmin) && (
                      <>
                        <Button
                          size="small"
                          color="primary"
                          variant="text"
                          startIcon={<Settings />}
                          style={{ height: 24 }}
                          onClick={() => setOpen(true)}
                        >
                          {t("ownCompany.setNavTechnicalUser")}
                        </Button>
                        <OwnCompanyAddTechUser open={open} setOpen={setOpen} />
                      </>
                    )}
                  </Box>
                }
              />
              <CardContent>
                <Grid container>
                  <Grid item xs={12} sm={12} md={12} lg={4}>
                    <Typography variant="body2" style={{ color: COLORS.lightGrey }}>
                      {t("ownCompany.login")}:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={8}>
                    <Typography variant="body2">{tenant?.technicalLogin || "-"}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={4}>
                    <Typography variant="body2" style={{ color: COLORS.lightGrey }}>
                      {t("ownCompany.signatureKey")}:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={8}>
                    <Typography variant="body2">{tenant?.technicalSignatureKey || "-"}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={4}>
                    <Typography variant="body2" style={{ color: COLORS.lightGrey }}>
                      {t("ownCompany.exchangeKey")}:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={8}>
                    <Typography variant="body2">{tenant?.technicalExchangeKey || "-"}</Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card style={{ height: "100%" }}>
              <CardHeader style={{ paddingBottom: 0 }} title={t("timebeezSync.title")} />
              <Box p={2}>
                <Box pb={1}>
                  {apiKeyLoading || getApiKeyQuery.isFetching ? (
                    <Box display="flex" justifyContent="center">
                      <CircularProgress size={20} />
                    </Box>
                  ) : (
                    <Typography>{getApiKeyQuery.data?.item || ""}</Typography>
                  )}
                </Box>
                <Box display="flex" width="100%" gridGap={8} pb={2}>
                  <Button
                    fullWidth
                    startIcon={
                      <FontAwesomeIcon icon={faWandMagicSparkles} style={{ fontSize: 16 }} />
                    }
                    onClick={() => apiKeyGenerate()}
                    disabled={apiKeyDeleteLoading || apiKeyLoading || getApiKeyQuery.isFetching}
                  >
                    {t("timebeezSync.generate")}
                  </Button>
                  <Button
                    fullWidth
                    variant="outlined"
                    startIcon={apiKeyDeleteLoading ? <CircularProgress size={20} /> : <Delete />}
                    onClick={() => apiKeyDelete()}
                    disabled={apiKeyDeleteLoading || apiKeyLoading || getApiKeyQuery.isFetching}
                  >
                    {t("common:button.delete")}
                  </Button>
                </Box>
                <TextField
                  name="timebeezApiKey"
                  label={t("timebeezApiKey")}
                  InputProps={{
                    endAdornment: (
                      <Button startIcon={<Check />} size="small" onClick={onSubmit}>
                        {t("common:button.save")}
                      </Button>
                    ),
                  }}
                  value={timebeezApiKey}
                  onChange={event => setTimebeezApiKey(event.target.value)}
                />
              </Box>
            </Card>
          </Grid>
        </Grid>
      )}
      {!isPartner && <ContractType />}
      {hasAuthority(account.user, account.permissions, selectedRelTenant, ["BANK_ACCOUNT_GET"]) && (
        <Card style={{ backgroundColor: "rgba(255, 255, 255, 0.6)" }}>
          <Box display="flex" alignItems="flex-end" justifyContent="space-between" p={2}>
            <Typography variant="h2">{t("tenant.tabs.accountNumber")}</Typography>
            {hasAuthority(account.user, account.permissions, selectedRelTenant, [
              "BANK_ACCOUNT_ADMIN",
            ]) && (
              <Button
                color="primary"
                variant="text"
                component={Link}
                to="/admin/tenants/tenant-details/bank-accounts/bank-account-create"
                startIcon={<AddBox />}
                style={{ height: 24 }}
              >
                {t("bankAccount.create")}
              </Button>
            )}
          </Box>
          <BankAccountList transparent />
        </Card>
      )}
      {hasAuthority(account.user, account.permissions, selectedRelTenant, ["DEPARTRMENT_GET"]) && (
        <Card style={{ backgroundColor: "rgba(255, 255, 255, 0.6)" }}>
          <Box display="flex" alignItems="flex-end" justifyContent="space-between" p={2}>
            <Typography variant="h2">{t("tenant.tabs.department")}</Typography>
            {hasAuthority(account.user, account.permissions, selectedRelTenant, [
              "DEPARTRMENT_ADMIN",
            ]) && (
              <Button
                color="primary"
                variant="text"
                component={Link}
                to="/my-company/departments/department-create"
                startIcon={<AddBox />}
                style={{ height: 24 }}
              >
                {t("department.create").toString()}
              </Button>
            )}
          </Box>
          <TenantSites />
        </Card>
      )}
    </Box>
  );
};
export default TenantProfileDetails;
